import { useIntl } from 'react-intl';
import './Truck.scss';
import truckImage from '../../assets/truck2.png';
import React from 'react';

const Truck = () => {
	const { formatMessage } = useIntl();

	const description = formatMessage({ id: 'home.truck.content.description' })
		.split('<br />')
		.map((text, index) => (
			<React.Fragment key={index}>
				{text}
				<br />
			</React.Fragment>
		));

	return (
		<section className='truck__section h-100'>
			<div className='container-fluid'>
				<div className='row'>
					<div className='col-md-12 p-0 position-relative h-100'>
						<img
							src={truckImage}
							alt='Background'
							className='img-fluid trackImage object-fit-fill w-100'
						/>
						<div className='text-overlay position-absolute top-0 left-0 right-0 d-flex align-items-center justify-content-center pt-md-5 pt-3 w-100'>
							<div className='col-md-8 text-center'>
								<p className='text__green mb-0 fw-bold'>{formatMessage({ id: 'truck.header' })}</p>
								<h1 className='text-black fw-bold'>
									{formatMessage({ id: 'home.truck.content.caption' })}
								</h1>
								<p className='text-black'>{description}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Truck;
