import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../db';
import { UserType } from '../../types';

/**
 * Stores or updates user data in Firestore
 * @param {Object} userData The user data to store/update
 */
export const storeUserData = async (userData: any) => {
	const userRef = doc(db, 'users', userData.uid);
	const docSnap = await getDoc(userRef);

	if (docSnap.exists()) {
		await setDoc(userRef, userData, { merge: true });
	} else {
		await setDoc(userRef, userData);
	}
};

/**
 * Retrieves user data from Firestore
 * @param {string} uid The user ID
 * @returns {Object} The user data
 */

export const getUserData = async (uid: string) => {
	const userRef = doc(db, 'users', uid);
	const docSnap = await getDoc(userRef);

	if (docSnap.exists()) {
		return docSnap.data();
	} else {
		return null;
	}
};

/**
 * Checkes if the user exists in Firestore
 * @param {string} uid The user ID
 * @returns {boolean} True if the user exists, false otherwise
 */

export const userExists = async (uid: string) => {
	const userRef = doc(db, 'users', uid);
	const docSnap = await getDoc(userRef);

	return docSnap.exists();
};
