import { useEffect, useMemo, useState } from 'react';
import logo from '../../assets/logo.png';
import './navbar.scss';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../redux/authSlice';
import { useNavigate } from 'react-router-dom';
import profile from '../../assets/profile.png';
import cartIcon from '../../assets/cart.png';
import orderIcon from '../../assets/order.png';
import useInterval from '../../hooks/use-interval';
import { CartType, UserType } from '../../types';
import { getCartData } from '../../config/firestore/cartData';
import { DocumentData } from 'firebase/firestore';
import { getUserData, userExists } from '../../config/firestore/userData';
import { useSnackbar } from 'notistack';
import { useLocale } from '../../hooks/use-locale';
import { useIntl } from 'react-intl';

const Navbar = () => {
	const { formatMessage } = useIntl();
	const { pathname, hash } = useLocation();
	const { enqueueSnackbar } = useSnackbar();
	const { isAuthenticated, user } = useSelector((state: any) => state.otp);
	const [cartData, setCartData] = useState<CartType | DocumentData | null>([]);
	const [userData, setUserData] = useState<UserType | DocumentData | null>(null);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { locale, setLocale } = useLocale();
	const [selectedLanguage, setSelectedLanguage] = useState<string>('🇩🇪 German'); // Track selected language
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const navLinks = [
		{ name: formatMessage({ id: 'navabr.home' }), url: '/' },
		{ name: formatMessage({ id: 'navbar.about.us' }), url: '/#about-us' },
		{ name: formatMessage({ id: 'navbar.our.services' }), url: '/#our-services' },
		{ name: formatMessage({ id: 'navbar.benefits' }), url: '/#benefits' },
		{ name: formatMessage({ id: 'navbar.contact.us' }), url: '/contact-us' },
		{ name: formatMessage({ id: 'navbar.food.trcuk' }), url: '/foodtruck' }
	];

	const switchLocale = (newLocale: string, displayName: string) => {
		setLocale(newLocale);
		setSelectedLanguage(displayName); // Update the displayed language
	};

	const handleLanguageChange = (lang: string, displayName: string): void => {
		switchLocale(lang, displayName);
	};

	const fetchCartData = async () => {
		const userJson = JSON.parse(user || '{}');
		const cartData = await getCartData(userJson.uid);
		setCartData(cartData);
		const userData = await getUserData(userJson.uid);
		setUserData(userData);
	};

	const checkUserExists = async () => {
		const userJson = JSON.parse(user || '{}');
		const exists = await userExists(userJson.uid);
		if (!exists) {
			dispatch(logout());
			enqueueSnackbar('User not found, Kindly Login', { variant: 'info' });
			navigate('/login');
		}
	};

	useInterval(() => {
		if (user) {
			checkUserExists();
		}
	}, 1000);

	useInterval(() => {
		if (user) {
			fetchCartData();
		} else {
			setCartData([]);
		}
	}, 800);

	useEffect(() => {
		const id = hash.replace('#', '');
		if (id) {
			setTimeout(() => {
				const element = document.getElementById(id);
				if (element) {
					const offset = 70;
					const elementPosition = element.getBoundingClientRect().top + window.pageYOffset - offset;
					window.scrollTo({ top: elementPosition, behavior: 'smooth' });
				}
			}, 100);
		}
	}, [hash]);

	const isActiveLink = useMemo(
		() => (linkUrl: string) => {
			return (
				(linkUrl === '/' && pathname === '/' && !hash) ||
				(linkUrl.startsWith('/#') && pathname === '/' && `#${linkUrl.split('/#')[1]}` === hash) ||
				(!linkUrl.startsWith('/#') && pathname.includes(linkUrl) && linkUrl !== '/')
			);
		},
		[pathname, hash]
	);

	const handleLogout = () => {
		dispatch(logout());
		enqueueSnackbar('Logout successful', { variant: 'success' });
		navigate('/login');
	};
	const [isOpen, setIsOpen] = useState(false);

	const toggleDropdown = () => setIsOpen(!isOpen);

	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	const closeMenu = () => {
		setIsMenuOpen(false); // Close the menu
	};

	return (
		<>
			<nav className='navbar navbar-expand-lg nav__section fixed-top'>
				<div className='container-fluid'>
					<a
						className='navbar-brand'
						href='/'
					>
						<img
							src={logo}
							alt='logo'
							className='img-fluid logo'
						/>
					</a>
					{/* Toggler button for mobile */}
					<button
						className='navbar-toggler'
						type='button'
						onClick={toggleMenu}
						aria-expanded={isMenuOpen}
					>
						<span className='toggler-icon'></span>
						<span className='toggler-icon'></span>
						<span className='toggler-icon'></span>
					</button>

					<div
						className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`}
						id='navbarSupportedContent'
					>
						<ul className='navbar-nav ms-auto mb-2 mb-lg-0 align-items-baseline'>
							{navLinks.map((link, index) => (
								<li
									className='nav-item'
									key={index}
								>
									<a
										className={`nav-link ${isActiveLink(link.url) ? 'active' : 'text__orange'} fw-bold`}
										href={link.url}
										onClick={closeMenu} // Close menu on link click
									>
										{link.name}
									</a>
								</li>
							))}
						</ul>

						{/* Language Dropdown */}
						<div className='dropdown'>
							<button
								className='btn btn-secondary dropdown-toggle order__btn'
								type='button'
								id='languageDropdown'
								data-bs-toggle='dropdown'
								aria-expanded='false'
							>
								{selectedLanguage}
							</button>
							<ul
								className='dropdown-menu'
								aria-labelledby='languageDropdown'
							>
								<li>
									<button
										className='dropdown-item'
										onClick={() => handleLanguageChange('de', '🇩🇪 German')}
									>
										🇩🇪 German
									</button>
								</li>
								<li>
									<button
										className='dropdown-item'
										onClick={() => handleLanguageChange('en', '🇺🇸 English')}
									>
										🇺🇸 English
									</button>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</nav>
		</>
	);
};

export default Navbar;
