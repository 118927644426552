const BACKEND_BASE_URL = process.env.REACT_APP_EXPRESS_SERVER_URL;

const handleResponse = async (response: Response) => {
	if (!response.ok) {
		const error = await response.text();
		throw new Error(error || 'Failed to fetch data');
	}
	return response.json();
};

export const GET = async (endpoint: string) => {
	const response = await fetch(`${BACKEND_BASE_URL}/${endpoint}`, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		}
	});
	return handleResponse(response);
};

export const POST = async (endpoint: string, data: any) => {
	const response = await fetch(`${BACKEND_BASE_URL}/${endpoint}`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(data)
	});
	return handleResponse(response);
};
