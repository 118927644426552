import { useState } from 'react';
import OrdersTable from './orders-table';
import SubscriptionTable from './subscription-table';
import './all-orders.scss';

const AllOrders = () => {
	const [selectedOption, setSelectedOption] = useState('orders');

	return (
		<section className='all__section'>
			<div className='container-fluid'>
				<div className='row'>
					<div className='col-md-12'>
						<h1 className='text-center text-white'>All Orders (Admin Page)</h1>
					</div>
					<div className='col-md-12 mt-5'>
						<div className='allorders__container'>
							<div className='allorders__buttons'>
								<button
									className={selectedOption === 'orders' ? 'active' : ''}
									onClick={() => setSelectedOption('orders')}
								>
									Daily Orders
								</button>
								<button
									className={selectedOption === 'subscriptions' ? 'active' : ''}
									onClick={() => setSelectedOption('subscriptions')}
								>
									Subscriptions
								</button>
							</div>
						</div>
					</div>
					<div className='col-md-12'>
						{selectedOption === 'orders' && <OrdersTable />}
						{selectedOption === 'subscriptions' && <SubscriptionTable />}
					</div>
				</div>
			</div>
		</section>
	);
};

export default AllOrders;
