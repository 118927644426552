import '../../pages/menu/menu.scss';
import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { storeCartData, getCartData } from '../../config/firestore/cartData';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import veganMeal from '../../assets/veganMeal.jpeg';
import lassi from '../../assets/lassi.jpeg';
import ratia from '../../assets/ratia.jpeg';
import roti from '../../assets/roti.jpeg';
import veganSubzi from '../../assets/vegan.jpeg';
import vegSubzi from '../../assets/veg.jpeg';

interface MenuListCardProps {
	id: number;
	day: string;
	date: string;
	menu: string;
	price: number;
	type: string;
	meal: string;
	menuAvailable?: boolean;
}

const MenuListCard: React.FC<MenuListCardProps> = ({
	id,
	day,
	date,
	menu,
	price,
	type,
	meal,
	menuAvailable = true
}) => {
	const { enqueueSnackbar } = useSnackbar();
	const user = useSelector((state: any) => state.otp.user);
	const isAuthenticated = useSelector((state: any) => state.otp.isAuthenticated);
	const navigate = useNavigate();

	const isDateTodayOrFuture = (dateString?: string): boolean => {
		if (!dateString) return false;
		const dateToCheck = moment(dateString, 'DD MMM YYYY');
		const today = moment().startOf('day');
		return dateToCheck.isSameOrAfter(today);
	};

	const handleAddToCartClick = async () => {
		const userJson = JSON.parse(user || '{}');
		const cartFirestore = await getCartData(userJson.uid);
		const cartItem = { id, day, date, menu, price, type, quantity: 1, meal };

		if (cartFirestore) {
			const existingCartItemIndex = cartFirestore.items.findIndex((item: any) => item.id === id);
			if (existingCartItemIndex !== -1) {
				const updatedCart = cartFirestore.items.map((item: any, index: number) => {
					if (index === existingCartItemIndex) {
						return {
							...item,
							quantity: item.quantity + 1
						};
					}
					return item;
				});

				const totalPrice = updatedCart.reduce((acc: number, item: any) => acc + item.price * item.quantity, 0);
				const totalQuantity = updatedCart.reduce((acc: number, item: any) => acc + item.quantity, 0);

				if (userJson.uid) {
					await storeCartData({ uid: userJson.uid, cart: { items: updatedCart, totalQuantity, totalPrice } });
					enqueueSnackbar('Item added to cart', { variant: 'success' });
				} else {
					console.error('User UID is undefined');
					enqueueSnackbar('Error adding item to cart', { variant: 'error' });
				}
				return;
			}
		}

		const updatedCart = [...(cartFirestore?.items || []), cartItem];
		const totalPrice = updatedCart.reduce((acc: number, item: any) => acc + item.price * item.quantity, 0);
		const totalQuantity = updatedCart.reduce((acc: number, item: any) => acc + item.quantity, 0);

		if (userJson.uid) {
			await storeCartData({ uid: userJson.uid, cart: { items: updatedCart, totalQuantity, totalPrice } });
			enqueueSnackbar('Item added to cart', { variant: 'success' });
		} else {
			console.error('User UID is undefined');
			enqueueSnackbar('Error adding item to cart', { variant: 'error' });
		}
	};

	const canOrder = isDateTodayOrFuture(date);

	const getMealImage = () => {
		switch (meal) {
			case 'MEAL':
				return veganMeal;
			case 'LASSI':
				return lassi;
			case 'BOONDI RAITA':
				return ratia;
			case 'VEGAN SUBZI':
				return veganSubzi;
			case 'VEGETARIAN SUBZI':
				return vegSubzi;
			case 'EXTRA':
				return roti;
			default:
				return null;
		}
	};

	return (
		<div className='card mb-3'>
			<div className='card-body py-1.5'>
				<div className='row align-items-center'>
					<div className='col-md-12'>
						<div className='row align-items-center'>
							<div className='col-md-12 pe-md-0'>
								<img
									src={getMealImage() ?? veganMeal}
									alt='Delicious meal'
									className='img-fluid d-block mx-auto rounded-circle border border-secondary'
									style={{ width: '100px', height: '100px' }}
								/>
							</div>
							<div className='col-md-12 text-center mt-md-0 mt-3'>
								<p className='text-white mb-0 fw-bold mt-3'>{meal}</p>
								<p className='text__gray__three'>{menu}</p>
							</div>
						</div>
					</div>
					<div className='col-md-12 text-center'>
						<h6 className='text__green fw-bold text-center mb-0'>€ {price.toFixed(2)}</h6>
						{canOrder && menuAvailable ? (
							isAuthenticated ? (
								<button
									className='btn text__green fw-bold text-md-end text-center cursor-pointer text-decoration-none'
									onClick={handleAddToCartClick}
									style={{ backgroundColor: 'transparent', border: 'none' }}
								>
									Add to cart
								</button>
							) : (
								<button
									className='btn text__green fw-bold text-md-end text-center cursor-pointer text-decoration-none'
									onClick={() => navigate('/login')}
									style={{ backgroundColor: 'transparent', border: 'none' }}
								>
									Login to order
								</button>
							)
						) : (
							<small className='text-white opacity-25'>Ordering unavailable</small>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default MenuListCard;
