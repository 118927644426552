import React from 'react';
import CartItem from './CartItem';

const CartItemList = ({ items }: { items: any[] }) => {
	return (
		<div>
			{items.map((item: any) => (
				<CartItem
					key={item.id}
					{...item}
				/>
			))}
		</div>
	);
};

export default CartItemList;
