import React, { useState, useEffect } from 'react';
import moment from 'moment';
import './menu.scss';
import MenuList from '../../sections/menu/MenuList';
import { getFutureDate } from '../../utils/get-dates';
import MenuHeading from '../../sections/menu/MenuHeading';

interface Tab {
	id: number;
	title: string;
	date: string;
}

const generateTabs = (): Tab[] => {
	const tabs: Tab[] = [];
	let dayNumber = 1;
	while (tabs.length < 5) {
		const date = getFutureDate(dayNumber);
		if (moment(date, 'DD MMM YYYY').day() !== 0 && moment(date, 'DD MMM YYYY').day() !== 6) {
			tabs.push({
				id: dayNumber,
				title: getFutureDate(dayNumber),
				date: date
			});
		}
		dayNumber++;
	}
	return tabs;
};

const tabs = generateTabs();

const Menu: React.FC = () => {
	const [selectedTab, setSelectedTab] = useState<string | null>(tabs[0].date);
	const [selectedTabName, setSelectedTabName] = useState<string | null>(tabs[0].title);
	const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);

	useEffect(() => {
		const handleResize = () => {
			setIsSmallScreen(window.innerWidth <= 435);
		};
		handleResize();
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [isSmallScreen]);

	const handleTabSelect = (tab: Tab) => {
		setSelectedTab(tab.date);
		setSelectedTabName(tab.title);
	};

	return (
		<section className='menu__section mt-3'>
			<div className='container'>
				<MenuHeading />
				<div className='row justify-content-center menu__tabs mt-md-4 pt-5'>
					<div className='col-md-10 d-flex justify-content-center'>
						{isSmallScreen ? (
							<div className='dropdown'>
								<button
									className='btn dropdown-toggle d-flex justify-content-center px-3 py-2'
									type='button'
									id='dropdownMenuButton'
									data-bs-toggle='dropdown'
									aria-expanded='false'
								>
									{selectedTabName}
								</button>
								<ul
									className='dropdown-menu'
									aria-labelledby='dropdownMenuButton'
								>
									{tabs.map(tab => (
										<li key={tab.id}>
											<button
												className={`dropdown-item ${
													selectedTab === tab.date ? 'active' : 'text__gray__three'
												}`}
												onClick={() => handleTabSelect(tab)}
												style={{
													backgroundColor:
														selectedTab === tab.date
															? 'var(--active-border-color)'
															: 'transparent',
													fontSize: '1rem'
												}}
											>
												{tab.title} <br />
											</button>
										</li>
									))}
								</ul>
							</div>
						) : (
							<div className='nav-wrapper'>
								<ul
									className='nav nav-tabs p-2'
									id='myTab'
									role='tablist'
								>
									{tabs.map(tab => (
										<li
											className='nav-item'
											role='presentation'
											key={tab.id}
										>
											<button
												className={`nav-link ${selectedTab === tab.date ? 'active' : ''}`}
												onClick={() => handleTabSelect(tab)}
												role='tab'
												id={`tab-${tab.date}`}
												aria-controls={`tabpanel-${tab.date}`}
												aria-selected={selectedTab === tab.date ? 'true' : 'false'}
											>
												{tab.title} <br />
											</button>
										</li>
									))}
								</ul>
							</div>
						)}
					</div>
				</div>

				<div
					className='tab-content mb-5'
					id='myTabContent'
				>
					{tabs.map(tab => (
						<div
							className={`tab-pane fade ${selectedTab === tab.date ? 'show active' : ''}`}
							id={tab.date}
							role='tabpanel'
							aria-labelledby={`${tab.date}-tab`}
							key={tab.date}
						>
							<MenuList date={tab.title} />
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

export default Menu;
