import React from 'react';
import { TagGreen } from '../../Common/Tags';
import './Benefits.scss';
import cookOne from '../../assets/cook1.svg';
import cookTwo from '../../assets/cook2.svg';
import cookThree from '../../assets/cook3.svg';
import cookFour from '../../assets/cook4.svg';
import cookFive from '../../assets/cook5.svg';
import cookSix from '../../assets/cook6.svg';
import { BenefitsCard, BenefitsCardCenter } from './benefits-card';
import { useIntl } from 'react-intl';

const Benefits = () => {
	const { formatMessage } = useIntl();
	return (
		<section
			className='benefits d-flex align-items-center'
			id='benefits'
		>
			<div className='container'>
				<div className='row justify-content-center mt-md-0 mt-5'>
					<div className='col-md-12 text-center'>
						<TagGreen title='KADAMBA BENEFITS' />
						<h1 className='text__black fw-bold mt-3'>{formatMessage({ id: 'benefits.header' })}</h1>
					</div>
					<div className='col-md-12 mt-5'>
						<div className='row justify-content-center'>
							<div className='col-md-4 d-flex flex-column'>
								<div className='row flex-grow-1'>
									<BenefitsCard
										title={formatMessage({ id: 'benefits.card.one.title' })}
										description={formatMessage({ id: 'benefits.card.one.description' })}
										imageUrl={cookOne}
										background='#FEF6EB'
									/>
								</div>
								<div className='row flex-grow-1 mt-4'>
									<BenefitsCard
										title={formatMessage({ id: 'benefits.card.two.title' })}
										description={formatMessage({ id: 'benefits.card.two.description' })}
										imageUrl={cookThree}
										background='#F1F6FC'
									/>
								</div>
							</div>
							{/* <div className='col-md-4 d-flex flex-column'>
								<div className='row flex-grow-1'>
									<BenefitsCardCenter
										title='High-Quality Ingredients'
										description='Our catering service offers a diverse and expertly crafted menu, showcasing ​a range of delicious dishes that cater to various tastes and dietary preferences and requirements.'
										imageUrl={cookFive}
										imageUrlTwo={cookSix}
										background='#F6FCF1'
									/>
								</div>
							</div> */}
							<div className='col-md-4 d-flex flex-column'>
								<div className='row flex-grow-1'>
									<BenefitsCard
										title={formatMessage({ id: 'benefits.card.three.title' })}
										description={formatMessage({ id: 'benefits.card.three.description' })}
										imageUrl={cookTwo}
										background='#F1F1F1'
									/>
								</div>
								<div className='row flex-grow-1 mt-4'>
									<BenefitsCard
										title={formatMessage({ id: 'benefits.card.four.title' })}
										description={formatMessage({ id: 'benefits.card.four.description' })}
										imageUrl={cookFour}
										background='#F5F1FC'
									/>
								</div>
							</div>
						</div>
					</div>
					<div className='col-md-12'>
						<h4 className='text__black text-center fw-bold mt-4'>
							{formatMessage({ id: 'benefits.description' })}
						</h4>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Benefits;
