import React from 'react';
import './benefits-card.scss';

interface BenefitsCardProps {
	title: string;
	description: string;
	imageUrl: string;
	background: string;
}

export const BenefitsCard: React.FC<BenefitsCardProps> = ({ title, description, imageUrl, background }) => {
	const cardStyle = {
		background: `${background}`
	};
	return (
		<section className='benefitsCard__section mt-3'>
			<div className='container'>
				<div className='row'>
					<div className='col-md-12'>
						<div
							className='card'
							style={cardStyle}
						>
							<div className='card-body pb-0'>
								<div className='row'>
									<div className='col-md-12'>
										<h6 className='fw-bold text__black'>{title}</h6>
									</div>
									<div className='col-7 mb-5 pe-0'>
										<p className='text__gray__secondary'>{description}</p>
									</div>
									<div className='col-5 p-0 d-flex align-items-end'>
										<img
											src={imageUrl}
											alt='cookOne'
											className='img-fluid'
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

interface BenefitsCardCenterProps extends BenefitsCardProps {
	imageUrlTwo: string;
}

export const BenefitsCardCenter: React.FC<BenefitsCardCenterProps> = ({
	title,
	description,
	imageUrl,
	background,
	imageUrlTwo
}) => {
	const cardStyle = {
		background: `${background}`
	};
	return (
		<section className='benefitsCard__section mt-3'>
			<div className='container'>
				<div className='row'>
					<div className='col-md-12'>
						<div
							className='card'
							style={cardStyle}
						>
							<div className='card-body pb-0'>
								<div className='row'>
									<div className='col-md-12'>
										<img
											src={imageUrl}
											alt='cookOne'
											className='img-fluid'
										/>
									</div>
									<div className='col-md-12 my-4'>
										<h6 className='fw-bold text__black'>{title}</h6>
										<p className='text__gray__secondary'>{description}</p>
									</div>
									<div className='col-md-12'>
										<img
											src={imageUrlTwo}
											alt='cookOne'
											className='img-fluid'
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
