import { getDocs, collection } from 'firebase/firestore';
import { db } from '../db';
import { getUserData } from './userData';

export const fetchOrdersWithUsers = async () => {
	const ordersRef = collection(db, 'orders');

	try {
		const ordersSnapshot = await getDocs(ordersRef);
		const userFetchPromises = ordersSnapshot.docs.map(async orderDoc => {
			const orderData = orderDoc.data();

			// console.group(orderData);

			const promises = orderData.history.map(async (element: any) => {
				try {
					const perUserData = await getUserData(element.uid);

					// add this user data in element object
					if (perUserData) {
						element.user = perUserData;
					}
					return element;
				} catch (userDataError) {
					console.error(`Error fetching user data for uid ${element.uid}:`, userDataError);
					// throw userDataError;
				}
			});
			return Promise.all(promises);
		});

		const combinedData = await Promise.all(userFetchPromises);
		return combinedData;
	} catch (error) {
		console.error('Error fetching orders with user data:', error);
		throw error;
	}
};

export const fetchSubscriptionsWithUsers = async () => {
	const subscriptionsRef = collection(db, 'subscriptions');

	try {
		const subscriptionsSnapshot = await getDocs(subscriptionsRef);
		const userFetchPromises = subscriptionsSnapshot.docs.map(async subscriptionDoc => {
			const subscriptionData = subscriptionDoc.data();

			const promises = subscriptionData.history.map(async (element: any) => {
				try {
					const perUserData = await getUserData(element.uid);

					// add this user data in element object
					if (perUserData) {
						element.user = perUserData;
					}
					// console.group('element  in subscription all :', element);
					return element;
				} catch (userDataError) {
					console.error(`Error fetching user data for uid ${element.uid}:`, userDataError);
					// throw userDataError;
				}
			});
			return Promise.all(promises);
		});

		const combinedData = await Promise.all(userFetchPromises);
		return combinedData;
	} catch (error) {
		console.error('Error fetching subscriptions with user data:', error);
		throw error;
	}
};
