const MenuHeading = () => {
  return (
		<>
			<div className='row justify-content-center'>
				<div className='col-md-10'>
					<h1 className='text-center text-white text-uppercase'>Where every meal feels like home</h1>
					<p className='text__gray__three text-center'>
						Indulge yourself in a culinary experience you won’t forget! Elevate your day with our exquisite
						food delivery service, where every dish is a masterpiece crafted with passion and precision.
					</p>
				</div>
			</div>
		</>
  );
}

export default MenuHeading