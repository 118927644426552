import { useIntl } from 'react-intl';
import { PaymentCard } from '../../Common/ContactCard';
import './Payment.scss';
import Info from './Info';
import ContactForm from './contact-form';

const Payment = () => {
	const { formatMessage } = useIntl();

	return (
		<section className='payment__section'>
			<div className='container'>
				<div className='row'>
					<h1 className='col-md-12 text__green fw-bold text-center text-uppercase'>
						{formatMessage({ id: 'navbar.contact.us' })}
					</h1>
					<p className='text__green text-center fw-bold'>{formatMessage({ id: 'contact.us.header' })}</p>
				</div>
				<div className='row justify-content-start mt-5 -pt-5'>
					<div className='col-md-6'>
						{/* <PaymentCard /> */}
						<ContactForm />
						{/* <ContactCard /> */}
					</div>
					<div className='col-md-6'>
						<Info />
					</div>
				</div>
			</div>
		</section>
	);
};

export default Payment;
