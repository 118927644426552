import  { useEffect, useState } from 'react';
import './all-orders.scss';
import { fetchSubscriptionsWithUsers } from '../../config/firestore/allOrder';
import { Table } from 'react-bootstrap';
import moment from 'moment';
import { useSelector } from 'react-redux';

const SubscriptionTable = () => {
	const { user } = useSelector((state: any) => state.otp);
	const [subscribers, setAllSubscribers] = useState<any>([]);
	const [searchQuery, setSearchQuery] = useState<string>('');

	useEffect(() => {
		const fetchData = async () => {
			try {
				if (user) {
					const userJson = JSON.parse(user);
					const userSubs = await fetchSubscriptionsWithUsers();
					setAllSubscribers(userSubs);
				}
			} catch (error) {
				console.log(error);
			}
		};
		fetchData();
	}, [user]);

	const filterOrders = () => {
		const filteredOrders = subscribers.filter((ordersArray: any) =>
			ordersArray.some(
				(order: any) =>
					order.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
					order.lastName.toLowerCase().includes(searchQuery.toLowerCase())
			)
		);

		return filteredOrders;
	};

	return (
		<div className='allorders__container mt-5'>
			<div className='container-fluid'>
				<div className='row'>
					<div className='col-md-4'>
						<input
							type='text'
							className='form-control mb-3'
							placeholder='Search by user name'
							value={searchQuery}
							onChange={e => setSearchQuery(e.target.value)}
						/>
					</div>
				</div>

				<div className='row'>
					<div className='col-md-12'>
						<Table
							striped
							bordered
							hover
							responsive='sm'
							className='table-dark'
						>
							<thead>
								<tr>
									<th>Sr. No.</th>
									<th>User</th>
									<th>Phone No.</th>
									<th>Email</th>
									<th>Company Name</th>
									<th>Adress</th>
									<th>Subscription Type</th>
									<th>Subscription Frequency</th>
									<th>Start Date</th>
									<th>End Date</th>
									<th>Total Price</th>
								</tr>
							</thead>
							<tbody>
								{filterOrders().length === 0 ? (
									<tr>
										<td colSpan={9}>No matching orders found</td>
									</tr>
								) : (
									filterOrders().map((order: any, index: number) =>
										order.map((sub: any, index: number) => (
											<tr key={index}>
												<td>{index + 1}</td>
												<td>
													<div className='d-flex align-items-center'>
														<img
															src={sub.user.photoURL}
															alt={`${sub.firstName} ${sub.lastName}`}
															className='rounded-circle'
															style={{
																width: '50px',
																height: '50px',
																objectFit: 'cover'
															}}
														/>
														<span>{`${sub.firstName} ${sub.lastName}`}</span>
													</div>
												</td>
												<td>{sub.phoneNumber}</td>
												<td>{sub.email}</td>
												<td>{sub.companyName}</td>
												<td>
													{sub.addressLine1} {sub.city} {sub.state} {sub.postalCode}
												</td>
												<td>{sub.subscription_type}</td>
												<td>{sub.subscriptionFrequency}</td>
												<td>{moment(sub.startDate).format('DD MMM YYYY')}</td>
												<td>{moment(sub.endDate).format('DD MMM YYYY')}</td>
												<td>€ {sub.totalPrice}</td>
											</tr>
										))
									)
								)}
							</tbody>
						</Table>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SubscriptionTable;
