import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../db';
import { SubscriptionHistoryType } from '../../types';

export const placeSubscription = async (uid: string, subscription: SubscriptionHistoryType) => {
	const subscriptionRef = doc(db, 'subscriptions', uid);

	try {
		await setDoc(subscriptionRef, { history: subscription.history });
	} catch (error) {
		console.error('Error placing subscription: ', error);
		throw error;
	}
};

export const getSubscriptionHistory = async (uid: string) => {
	const subscriptionRef = doc(db, 'subscriptions', uid);

	try {
		const subscriptionSnap = await getDoc(subscriptionRef);
		if (subscriptionSnap.exists()) {
			return subscriptionSnap.data();
		} else {
			return null;
		}
	} catch (error) {
		console.error('Error getting subscription history: ', error);
		throw error;
	}
};
