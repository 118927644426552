import { weekDates } from '../utils/get-dates';

let idCounter = 1;
interface MenuItem {
	id: number;
	type: string;
	day: string;
	menu: string;
	price: number;
	meal: string;
}

interface MenuDateItem {
	date: string;
	items: MenuItem[];
}

function generateMenuItems(
	day: string,
	veganMenu: string,
	vegetarianMenu: string,
	hasSalad: boolean,
	priceVegan = 8.9,
	priceVegetarian = 9.9,
	veganSubzi = 6.9,
	vegetarianSubzi = 7.9
): MenuItem[] {
	const saladComponent = hasSalad ? 'Salad . ' : '';
	return [
		{
			id: idCounter++,
			meal: 'MEAL',
			type: 'VEGAN',
			day,
			menu: `Dal . Jira Rice . Chutney . ${saladComponent} ${veganMenu} . Sweet of the day`,
			price: priceVegan
		},
		{
			id: idCounter++,
			meal: 'MEAL',
			type: 'VEGETARIAN',
			day,
			menu: `Dal . Jira Rice . Chutney . ${saladComponent} ${vegetarianMenu} . Sweet of the day`,
			price: priceVegetarian
		},
		{
			id: idCounter++,
			meal: 'VEGAN SUBZI',
			type: 'VEGAN',
			day,
			menu: `${veganMenu}`,
			price: veganSubzi
		},
		{
			id: idCounter++,
			meal: 'VEGETARIAN SUBZI',
			type: 'VEGETARIAN',
			day,
			menu: `${vegetarianMenu}`,
			price: vegetarianSubzi
		},
		{
			id: idCounter++,
			meal: 'OPTIONAL',
			type: 'OPTIONAL',
			day,
			menu: 'Dal . Jira Rice',
			price: 5.9
		},
		{
			id: idCounter++,
			meal: 'EXTRA',
			type: 'EXTRA',
			day,
			menu: 'Chapati',
			price: 1.5
		},
		{
			id: idCounter++,
			meal: 'BOONDI RAITA',
			type: 'VEGETARIAN',
			day,
			menu: 'Yogurt',
			price: 3.9
		},
		{
			id: idCounter++,
			meal: 'BOONDI RAITA',
			type: 'VEGAN',
			day,
			menu: 'Vegan Yogurt',
			price: 3.9
		},
		{
			id: idCounter++,
			meal: 'LASSI',
			type: 'VEGAN',
			day,
			menu: 'Vegan Mango Lassi, 250ml',
			price: 3.9
		},
		{
			id: idCounter++,
			meal: 'LASSI',
			type: 'VEGETARIAN',
			day,
			menu: 'Mango Lassi, 250ml',
			price: 3.9
		}
	];
}

export const newMenuItems: MenuDateItem[] = [];

const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
const hasSalad = [false, true, false, true, false];
const veganMenus = [
    'Jeera Aloo',
    'Punjabi Chole',
    'Aloo Palak',
    'Pumpkin subzi',
    'Rajma Masala', // place holder for 1st week
    'Jeera Aloo',
    'Punjabi Chole',
    'Aloo Palak',
    'Pumpkin subzi',
    'Rajma Masala', // place holder for 2nd week
    'Jeera Aloo',
    'Punjabi Chole',
    'Aloo Palak',
    'Pumpkin subzi',
    'Rajma Masala', // place holder for 3rd week
    'Jeera Aloo',
    'Punjabi Chole',
    'Aloo Palak',
    'Pumpkin subzi',
    'Rajma Masala', // place holder for 4th week
    'Jeera Aloo',
    'Punjabi Chole',
    'Aloo Palak',
    'Pumpkin subzi',
    'Rajma Masala', // place holder for 5th week
    'Jeera Aloo',
    'Punjabi Chole',
    'Aloo Palak',
    'Pumpkin subzi',
    'Rajma Masala', // place holder for 6th week
    
];

const vegetarianMenus = [
    
    'Paneer Butter Masala',
    'Shahi Paneer',
    'Palak Paneer',
    'Kadhai Paneer',
    'Paneer Tikka Masala', // place holder for 1st week
    'Paneer Butter Masala',
    'Shahi Paneer',
    'Palak Paneer',
    'Kadhai Paneer',
    'Paneer Tikka Masala', // place holder for 2nd week
    'Paneer Butter Masala',
    'Shahi Paneer',
    'Palak Paneer',
    'Kadhai Paneer',
    'Paneer Tikka Masala', // place holder for 3rd week
    'Paneer Butter Masala',
    'Shahi Paneer',
    'Palak Paneer',
    'Kadhai Paneer',
    'Paneer Tikka Masala', // place holder for 4th week
    'Paneer Butter Masala',
    'Shahi Paneer',
    'Palak Paneer',
    'Kadhai Paneer',
    'Paneer Tikka Masala', // place holder for 5th week
    'Paneer Butter Masala',
    'Shahi Paneer',
    'Palak Paneer',
    'Kadhai Paneer',
    'Paneer Tikka Masala', // place holder for 6th week
];

// Generate menu items for 4 weeks
for (let week = 0; week < 6; week++) {
	for (let dayIndex = 0; dayIndex < 5; dayIndex++) {
		const day = daysOfWeek[dayIndex];
		const menuDate = weekDates(week, dayIndex);
		const menuItems = generateMenuItems(
			day,
			veganMenus[week * 5 + dayIndex],
			vegetarianMenus[week * 5 + dayIndex],
			hasSalad[dayIndex]
		);
		newMenuItems.push({
			date: menuDate,
			items: menuItems
		});
	}
}
