import moment from 'moment';

type InputTypes = 'Weekly' | 'Two Weeks' | 'Monthly' | 'None';

export const getSubscriptionLength = (
	input: InputTypes
): {
	startDate: string;
	endDate: string;
} => {
	if (input === 'None') return { startDate: '', endDate: '' };

	// setting the start date to the next day
	let startDate = moment().add(1, 'day');
	let endDate = moment(startDate);

	switch (input) {
		case 'Weekly':
			endDate = startDate.clone().add(1, 'weeks');
			break;
		case 'Two Weeks':
			endDate = startDate.clone().add(2, 'weeks');
			break;
		case 'Monthly':
			endDate = startDate.clone().add(1, 'months');
			break;
	}

	// skipping the weekends ie Saturday and Sunday
	if (startDate.day() === 0) {
		startDate = startDate.add(1, 'days');
	} else if (startDate.day() === 6) {
		startDate = startDate.add(2, 'days');
	}

	if (endDate.day() === 0) {
		endDate = endDate.add(1, 'days');
	} else if (endDate.day() === 6) {
		endDate = endDate.add(2, 'days');
	}

	return {
		startDate: startDate.format('ddd DD MMM YYYY'),
		endDate: endDate.format('ddd DD MMM YYYY')
	};
};
