import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUserData } from '../config/firestore/userData';

// async thunk
export const fetchUserData = createAsyncThunk('userData/fetchUserData', async (uid: string, { rejectWithValue }) => {
	try {
		const response = await getUserData(uid);
		if (response) {
			return response;
		} else {
			return rejectWithValue('No user data found');
		}
	} catch (error) {
		return rejectWithValue(error as string);
	}
});

export const userDataSlice = createSlice({
	name: 'userData',
	initialState: {
		data: {},
		status: 'idle',
		error: null
	},
	reducers: {
		// reducer to clear user data if needed
		clearUserData: state => {
			state.data = {};
			state.status = 'idle';
			state.error = null;
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchUserData.pending, state => {
				state.status = 'loading';
			})
			.addCase(fetchUserData.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.data = action.payload; 
				state.error = null;
			})
			.addCase(fetchUserData.rejected, (state, action) => {
                state.status = 'failed';
                state.data = {};
			});
	}
});

// Export the clearUserData action for use in components
export const { clearUserData } = userDataSlice.actions;

// Export the reducer to be added to the store
export const userDataReducer = userDataSlice.reducer;
