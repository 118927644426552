import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../db';
import { OrderHistoryType } from '../../types';

export const placeOrder = async (uid: string, order: OrderHistoryType) => {
	const orderRef = doc(db, 'orders', uid);

	try {
		await setDoc(orderRef, { history: order.history });
	} catch (error) {
		console.error('Error placing order: ', error);
		throw error;
	}
};

export const getOrderHistory = async (uid: string) => {
	const orderRef = doc(db, 'orders', uid);

	try {
		const orderHistorySnapshot = await getDoc(orderRef);
		if (orderHistorySnapshot.exists()) {
			return orderHistorySnapshot.data();
		} else {
			return null;
		}
	} catch (error) {
		console.error('Error fetching order history: ', error);
		throw error;
	}
};
