import 'firebase/auth';
import { app } from '../config/firebase';
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut } from 'firebase/auth';

export const auth = getAuth(app);

export const signInWithGoogle = () => {
	return async (dispatch: any) => {
		try {
			const provider = new GoogleAuthProvider();
			provider.setCustomParameters({
				prompt: 'select_account '
			});

			const result = signInWithPopup(auth, provider);
			dispatch({ type: 'GOOGLE_AUTH_SUCCESS', result });
		} catch (error) {
			dispatch({ type: 'GOOGLE_AUTH_ERROR', error });
		}
	};
};

export const signOutUser = () => {
	return async (dispatch: any) => {
		try {
			const result = signOut(auth);
			dispatch({ type: 'SIGN_OUT_SUCCESS', result });
		} catch (error) {
			dispatch({ type: 'SIGN_OUT_ERROR', error });
		}
	};
};
