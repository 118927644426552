export const subscriptionType = [
	{
		type: 'Vegan'
	},
	{
		type: 'Vegetarian'
	}
];

export const subscriptionVeganFrequency = [
	{ type: 'Weekly', price: 40.9 },
	{ type: 'Two Weeks', price: 81.9 },
	{ type: 'Monthly', price: 175.9 }
];

export const subscriptionVegFrequency = [
	{ type: 'Weekly', price: 45.9 },
	{ type: 'Two Weeks', price: 91.9 },
	{ type: 'Monthly', price: 197.9 }
];

export const subscriptionFrequencyDeliveryCharges = [
	{ type: 'Weekly', price: 8 },
	{ type: 'Two Weeks', price: 16 },
	{ type: 'Monthly', price: 35 }
];
