import { useEffect, useState } from 'react';
import { Form, InputGroup, Modal } from 'react-bootstrap';
import './subscription.scss';
import flag from '../../assets/flag.png';
import { Stripe } from '@stripe/stripe-js';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { placeSubscription, getSubscriptionHistory } from '../../config/firestore/subscription';
import { getUserData, storeUserData } from '../../config/firestore/userData';
import { getSubscriptionLength } from '../../utils/subscription-length';
import { DocumentData } from 'firebase/firestore';
import { validPostalCodes } from '../../data/postalCode';
import useStripe from '../../hooks/use-stripe';
import { POST } from '../../api';
import { subscriptionType, subscriptionFrequencyDeliveryCharges, subscriptionVegFrequency, subscriptionVeganFrequency } from '../../data/subscription';

const Subscription = () => {
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const { stripe, error} = useStripe();
	const { user } = useSelector((state: any) => state.otp);
	const isAuthenticated = useSelector((state: any) => state.otp);
	const userJson = JSON.parse(user || '{}');
	const [stripePromise, setStripePromise] = useState<Stripe | null>(null);
	const [, setError] = useState(null);
	const [useDetails, setUserDetails] = useState<DocumentData | null>(null);
	const [loading, setLoading] = useState(false);
	const [formData, setFormData] = useState({
		uid: userJson.uid,
		firstName: '',
		lastName: '',
		email: userJson.email,
		phoneNumber: '',
		companyName: '',
		addressLine1: useDetails?.addressLine1,
		city: useDetails?.city,
		state: useDetails?.state,
		postalCode: useDetails?.postalCode,
		subscription_type: '',
		subscriptionFrequency: '',
		subscriptionFrequency_charges: 0,
		deliveryCharges: 0,
		totalPrice: 0,
		consent: 'no',
		startDate: '',
		endDate: ''
	});

	const [showPostalModal, setShowPostalModal] = useState<boolean>(false);
	const togglePostalModal = () => setShowPostalModal(!showPostalModal);

	// fetch the user details
	useEffect(() => {
		const fetchUserDetails = async () => {
			const userData = await getUserData(userJson.uid);
			setUserDetails(userData);
			setFormData(formData => ({
				...formData,
				firstName: userData?.fname,
				lastName: userData?.lname,
				email: userData?.email,
				phoneNumber: userData?.phoneNumber,
				addressLine1: userData?.addressLine1,
				city: userData?.city,
				state: userData?.state,
				postalCode: userData?.postalCode
			}));
		};
		if (user) {
			fetchUserDetails();
		}
	}, [userJson.uid]);

	
	// get the url
	useEffect(() => {
		// Function to extract JSON from URL params
		const extractJSONFromURL = async () => {
			const urlParams = new URLSearchParams(window.location.search);

			if (urlParams.size === 0) return;

			const orderDataParam = urlParams.get('formData');
			const successParam = urlParams.get('success');

			if (successParam && orderDataParam) {
				// Decode encoded symbols like %22 to "
				const decodedJSON = decodeURIComponent(orderDataParam.replace(/\+/g, ' '));

				try {
					const decodedFormDataSubscription = JSON.parse(decodedJSON);
					const userJson = JSON.parse(user || '{}');
					const subscriptionHistory = await getSubscriptionHistory(userJson.uid);

					const newSubscriptionHistory = subscriptionHistory
						? [...subscriptionHistory.history, decodedFormDataSubscription]
						: [decodedFormDataSubscription];
					await placeSubscription(userJson.uid, { history: newSubscriptionHistory });
					const userData = await getUserData(userJson.uid);
					const updatedUserData = { ...userData, isSubscribed: true };
					await storeUserData(updatedUserData);

					enqueueSnackbar('Order placed successfully', { variant: 'success' });
					navigate('/completion?type=subscription');
				} catch (error) {
					console.error('Error parsing JSON:', error);
				}
			} else if (!successParam) {
				enqueueSnackbar('Error placing order', { variant: 'error' });
				navigate('/subscription');
			}
		};

		extractJSONFromURL();
	}, []);

	const handleChange = (e: any) => {
		const { name, value, checked } = e.target;

		const updatedFormData = {
			...formData,
			[name]: value,
			consent: name === 'consent' ? (checked ? 'yes' : 'no') : formData.consent
		};

		setFormData(updatedFormData);
	};
	const handleSubscriptionTypeChange = (e: any) => {
		const { value } = e.target;

		// Update form data for subscription type and reset frequency-related fields
		setFormData({
			...formData,
			subscription_type: value,
			subscriptionFrequency: '',
			subscriptionFrequency_charges: 0,
			deliveryCharges: 0,
			totalPrice: 0,
			startDate: '',
			endDate: ''
		});
	};

	const getSubscriptionFrequencyData = () => {
		switch (formData.subscription_type) {
			case 'Vegan':
				return subscriptionVeganFrequency;
			case 'Vegetarian':
				return subscriptionVegFrequency;
			default:
				return [];
		}
	};

	const handleSubscriptionFrequency = (e: any) => {
		const { value } = e.target;
		const subscriptionFrequencyData = getSubscriptionFrequencyData();
		const selectedFrequency = subscriptionFrequencyData.find(item => item.type === value);
		const subscriptionFrequencyCharges = selectedFrequency ? selectedFrequency.price : 0;
		const deliveryCharges = subscriptionFrequencyDeliveryCharges.find(item => item.type === value)?.price || 0;
		const totalPrice = subscriptionFrequencyCharges + deliveryCharges;

		const { startDate, endDate } = getSubscriptionLength(value);

		setFormData({
			...formData,
			subscriptionFrequency: value,
			subscriptionFrequency_charges: subscriptionFrequencyCharges,
			deliveryCharges: deliveryCharges,
			totalPrice: totalPrice,
			startDate: startDate,
			endDate: endDate
		});
	};

	const handleConsentChange = (e: any) => {
		const { checked } = e.target;

		setFormData({
			...formData,
			consent: checked ? 'yes' : 'no'
		});
	};

	const handleSubmit = async (e: any) => {
		e.preventDefault();

		// Postal Code Validation
		if (!validPostalCodes.includes(formData.postalCode)) {
			enqueueSnackbar('We do not deliver to this address', { variant: 'error' });
			return; // Stop the submission if the postal code is not valid
		}

		try {
			setLoading(true);
			
			const response = await POST('create-checkout-session-subscription', formData);

			if (!response.ok) {
				setLoading(false);
				enqueueSnackbar('Network error, try again in sometime', { variant: 'info' });
			}
			try {
				if (!stripe) {
					console.error('Stripe not loaded');
					enqueueSnackbar('Stripe not loaded', { variant: 'error' });
					return;
				}

				const session = await response;
				await stripe.redirectToCheckout({ sessionId: session.id });
			} catch (error) {
				console.error('Error placing order:', error);
				enqueueSnackbar('Network error, try again in sometime', { variant: 'info' });
			}
		} catch (error) {
			console.error('Error placing order:', error);
			enqueueSnackbar('Network error, try again in sometime', { variant: 'info' });
		} finally {
			setLoading(false);
		}
	};

	return (
		<section className='subscription__section'>
			<div className='container'>
				<div className='row justify-content-center'>
					<div className='col-md-10 mt-md-0 mt-5'>
						<h1 className='fw-bold text-uppercase text-white text-center'>Meal Box Subscription Request</h1>
						<p className='text__gray__three text-center'>
							Please submit your requirements, and we will get back to you after checking the feasibility
							for delivery at your location. Thank you!
						</p>
					</div>
					<div className='col-md-6'>
						<div className='card'>
							<div className='card-body'>
								{isAuthenticated.isAuthenticated === true ? (
									<>
										<div className='row'>
											<div className='col-md-12'>
												<Form onSubmit={handleSubmit}>
													<div className='row'>
														<div className='col-md-6'>
															<Form.Label htmlFor='basic-first-name'>
																First Name
															</Form.Label>
															<InputGroup className='mb-3'>
																<Form.Control
																	type='text'
																	name='firstName'
																	value={formData.firstName}
																	onChange={handleChange}
																	placeholder='Enter first name'
																/>
															</InputGroup>
														</div>

														<div className='col-md-6'>
															<Form.Label htmlFor='basic-last-name'>Last Name</Form.Label>
															<InputGroup className='mb-3'>
																<Form.Control
																	type='text'
																	name='lastName'
																	value={formData.lastName}
																	onChange={handleChange}
																	placeholder='Enter last name'
																/>
															</InputGroup>
														</div>
													</div>

													<div>
														<Form.Label htmlFor='basic-email'>Email</Form.Label>
														<InputGroup className='mb-3'>
															<Form.Control
																type='email'
																name='email'
																value={formData.email}
																onChange={handleChange}
																placeholder='Enter email'
															/>
														</InputGroup>
													</div>
													<div>
														<Form.Label htmlFor='basic-phone'>Phone Number</Form.Label>
														<InputGroup className='mb-3'>
															<InputGroup.Text id='addon-wrapping'>
																<img
																	src={flag}
																	alt='German Flag'
																	className='img-fluid'
																	style={{ width: '20px', marginRight: '6px' }}
																/>
																+49
															</InputGroup.Text>
															<Form.Control
																type='text'
																name='phoneNumber'
																value={formData.phoneNumber}
																onChange={e => {
																	const re = /^\d{0,11}$/;
																	if (
																		e.target.value === '' ||
																		re.test(e.target.value)
																	) {
																		handleChange(e);
																	}
																}}
																placeholder='Enter phone number'
																aria-label='Phone'
																aria-describedby='basic-phone'
															/>
														</InputGroup>
													</div>
													<div>
														<Form.Label htmlFor='basic-company-name'>
															Company Name
														</Form.Label>
														<InputGroup className='mb-3'>
															<Form.Control
																type='text'
																name='companyName'
																value={formData.companyName}
																onChange={handleChange}
																placeholder='Enter company name'
															/>
														</InputGroup>
													</div>
													<div>
														<Form.Label htmlFor='basic-address'>
															Office Address (Where the meal box will be delivered)*
														</Form.Label>
														<InputGroup className='mb-3'>
															<Form.Control
																type='text'
																name='addressLine1'
																value={formData.addressLine1}
																onChange={handleChange}
																placeholder='Address Line 1'
															/>
														</InputGroup>
														<InputGroup className='mb-3'>
															<Form.Control
																type='text'
																name='city'
																value={formData.city}
																onChange={handleChange}
																placeholder='City'
															/>
														</InputGroup>
														<InputGroup className='mb-3'>
															<Form.Control
																type='text'
																name='state'
																value={formData.state}
																onChange={handleChange}
																placeholder='State/Province/Region'
															/>
														</InputGroup>
														<InputGroup>
															<Form.Control
																type='text'
																name='postalCode'
																value={formData.postalCode}
																onChange={handleChange}
																placeholder='Postal Code'
															/>
														</InputGroup>
														<div
															className='input-group-append text-end fw-lighter mb-3'
															style={{ fontSize: '12px' }}
														>
															<button
																className='bg-transparent text__green border-0'
																type='button'
																onClick={togglePostalModal}
															>
																Check postal code delivery range
															</button>
														</div>
													</div>
													<div className='mt-4'>
														<Form.Label>Choose your subscription type</Form.Label>
														<InputGroup className='mb-3'>
															{subscriptionType.map((item, index) => (
																<div
																	key={index}
																	className='form-check form-check-inline'
																>
																	<input
																		className='form-check-input'
																		type='radio'
																		name='subscription_type'
																		id={`inlineRadio${index}`}
																		value={item.type}
																		checked={
																			formData.subscription_type === item.type
																		}
																		onChange={handleSubscriptionTypeChange}
																	/>
																	<label
																		className='form-check-label'
																		htmlFor={`inlineRadio${index}`}
																	>
																		{item.type}
																	</label>
																</div>
															))}
														</InputGroup>
														<div className='mt-4'>
															<Form.Label>Chose your subscription frequency</Form.Label>
															<InputGroup className='mb-3'>
																{getSubscriptionFrequencyData().map((item, index) => (
																	<div
																		key={index}
																		className='form-check form-check-inline'
																	>
																		<input
																			className='form-check-input'
																			type='radio'
																			name='subscriptionFrequency'
																			id={`frequencyRadio${index}`}
																			value={item.type}
																			checked={
																				formData.subscriptionFrequency ===
																				item.type
																			}
																			onChange={handleSubscriptionFrequency}
																		/>
																		<label
																			className='form-check-label'
																			htmlFor={`frequencyRadio${index}`}
																		>
																			{item.type}
																		</label>
																	</div>
																))}
															</InputGroup>
														</div>
														{formData.totalPrice > 0 ? (
															<h5 className='mt-4 text__gray__three text-wrap'>
																Your subscription total will be:{' '}
																<span className='text__green fw-bold'>
																	€{formData.subscriptionFrequency_charges.toFixed(2)}{' '}
																	+ €{formData.deliveryCharges.toFixed(2)}
																</span>{' '}
																Delivery charges
															</h5>
														) : (
															''
														)}
													</div>
													<div className='mt-5'>
														<Form.Check
															type='checkbox'
															id='flexCheckDefault'
															label='I consent to having this website store my submitted information so they can respond to my inquiry.'
															name='consent'
															checked={formData.consent === 'yes'}
															onChange={handleConsentChange}
														/>
													</div>
													<button
														type='submit'
														disabled={
															formData.totalPrice === 0 ||
															formData.consent !== 'yes' ||
															loading
														}
														className='btn order__btn w-100 mt-4'
													>
														{loading ? (
															<div
																className='spinner-border text-light'
																role='status'
															>
																<span className='visually-hidden'>Loading...</span>
															</div>
														) : (
															<>
																Pay{' '}
																{formData.totalPrice > 0
																	? `€${formData.totalPrice.toFixed(2)}`
																	: ''}
															</>
														)}
													</button>
												</Form>
											</div>
										</div>
									</>
								) : (
									// ui to be added here
									<button
										className='btn text__green fw-bold text-md-end text-center cursor-pointer text-decoration-none d-block m-auto'
										onClick={() => navigate('/login')}
										style={{ backgroundColor: 'transparent', border: 'none' }}
									>
										Login to subscription
									</button>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			<Modal
				show={showPostalModal}
				onHide={togglePostalModal}
			>
				<Modal.Header closeButton>
					<Modal.Title>Deliverable Postal Codes</Modal.Title>
				</Modal.Header>
				<Modal.Body>{validPostalCodes.join(', ')}</Modal.Body>
			</Modal>
		</section>
	);
};

export default Subscription;
