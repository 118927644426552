import React, { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import './Payment.scss';

type Props = {};

const ContactForm = (props: Props) => {
	const [formData, setFormData] = useState({
		firstName: '',
		lastName: '',
		contactNumber: '',
		email: '',
		message: ''
	});

	const [status, setStatus] = useState<string | null>(null);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value
		});
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setStatus('Sending...');
		console.log('Form submitted:', formData);

		try {
			const response = await fetch('https://us-central1-kadamba-foods-fe.cloudfunctions.net/sendEmail', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					name: `${formData.firstName} ${formData.lastName}`, // Combine first and last name
					subject: 'Contact Us', // Fixed subject
					message: formData.message,
					email: formData.email,
					contactNumber: formData.contactNumber
				})
			});

			if (!response.ok) {
				throw new Error('Failed to send email');
			}

			const responseData = await response.json();
			console.log('Response from server:', responseData);

			if (responseData.result === 'Email sent successfully') {
				setStatus('Email sent successfully!');
				setFormData({
					firstName: '',
					lastName: '',
					contactNumber: '',
					email: '',
					message: ''
				}); // Reset form
			} else {
				setStatus('Failed to send email. Please try again.');
			}
		} catch (error) {
			setStatus('Failed to send email. Please try again.');
			console.error('Error sending email:', error);
		}
	};

	return (
		<section className='contact__form__section'>
			<div className='container'>
				{status && <p className={status.includes('successfully') ? 'text-success' : 'text-danger'}>{status}</p>}
				<div className='card'>
					<div className='card-body'>
						<Form onSubmit={handleSubmit}>
							<div className='mb-3'>
								<Form.Label htmlFor='firstName'>First Name</Form.Label>
								<InputGroup className='mb-3'>
									<Form.Control
										type='text'
										name='firstName'
										value={formData.firstName}
										onChange={handleChange}
										placeholder='Enter first name'
									/>
								</InputGroup>
							</div>
							<div className='mb-3'>
								<Form.Label htmlFor='lastName'>Last Name</Form.Label>
								<InputGroup className='mb-3'>
									<Form.Control
										type='text'
										name='lastName'
										value={formData.lastName}
										onChange={handleChange}
										placeholder='Enter last name'
									/>
								</InputGroup>
							</div>
							<div className='mb-3'>
								<Form.Label htmlFor='contactNumber'>Contact Number</Form.Label>
								<InputGroup className='mb-3'>
									<Form.Control
										type='text'
										name='contactNumber'
										value={formData.contactNumber}
										onChange={handleChange}
										placeholder='Enter Contact Number'
									/>
								</InputGroup>
							</div>
							<div className='mb-3'>
								<Form.Label htmlFor='email'>Email</Form.Label>
								<InputGroup className='mb-3'>
									<Form.Control
										type='text'
										name='email'
										value={formData.email}
										onChange={handleChange}
										placeholder='Enter Email'
									/>
								</InputGroup>
							</div>
							<div className='mb-3'>
								<Form.Label htmlFor='message'>Message</Form.Label>
								<InputGroup className='mb-3'>
									<Form.Control
										as='textarea'
										rows={5}
										type='text'
										name='message'
										value={formData.message}
										onChange={handleChange}
										placeholder='Enter your message'
										style={{ height: 'auto' }}
									/>
								</InputGroup>
							</div>
							<button
								type='submit'
								className='btn order__btn w-100 mt-4'
							>
								Submit
							</button>
						</Form>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ContactForm;
