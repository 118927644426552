import AboutUs from '../../components/about-us/about-us';
import Benefits from '../../components/benefits/Benefits';
import Contact from '../../components/contact/Contact';
import Hero from '../../components/hero/Hero';
import OurServices from '../../components/our-services/our-services';
import Truck from '../../components/truck/Truck';
import FoodImage from '../../components/images/food-images';

const Home = () => {
	return (
		<div className='App'>
			<Truck />
			{/* <Hero /> */}
			<AboutUs />
			<OurServices />
			<Benefits />

			<FoodImage />
			{/* <Contact /> */}
		</div>
	);
};

export default Home;
