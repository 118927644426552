import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './cart.scss';
import { getCartData, updateCartData } from '../../config/firestore/cartData';
import { getUserData } from '../../config/firestore/userData';
import useInterval from '../../hooks/use-interval';
import { getOrderHistory, placeOrder } from '../../config/firestore/orders';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import { POST } from '../../api';
import useStripe from '../../hooks/use-stripe';
import CartItemList from '../../sections/cart/CartItemList';
import SpecialInstructions from '../../sections/cart/SpecialInstructions';
import DeliveryAddress from '../../sections/cart/DeliveryAddress';
import PaymentSummary from '../../sections/cart/PaymentSummary';
import CardEmpty from '../../sections/cart/CardEmpty';

const customCookingInstruction = [{ type: 'Indian Spicy' }, { type: 'European Spicy' }];

const Cart = () => {
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const { user } = useSelector((state: any) => state.otp);
	const [cartData, setCartData] = useState<any>(null);
	const [userData, setUserData] = useState<any>(null);
	const { stripe, error } = useStripe();
	const [cookingInstructions, setCookingInstructions] = useState('');
	const [loading, setLoading] = useState(false);

	const isCartNotEmpty = cartData && cartData.items && cartData.items.length > 0;

	useEffect(() => {
		const checkIfCartItemsValid = async () => {
			const today = moment().format('DD MMM YYYY');
			const userJson = JSON.parse(user || '{}');
			const cartData = await getCartData(userJson.uid);

			const newCartItems = cartData?.items.filter((item: any) => {
				const itemDate = moment(item.date, 'DD MMM YYYY');
				return itemDate.isAfter(today);
			});

			const totalPrice = newCartItems.reduce((acc: number, item: any) => acc + item.price * item.quantity, 0);
			const totalQuantity = newCartItems.reduce((acc: number, item: any) => acc + item.quantity, 0);

			await updateCartData({ uid: userJson.uid, cart: { items: newCartItems, totalQuantity, totalPrice } });

			setCartData({ items: newCartItems, totalQuantity, totalPrice });
		};

		if (user) {
			checkIfCartItemsValid();
		}
	}, [user]);

	useEffect(() => {
		const extractJSONFromURL = async () => {
			const urlParams = new URLSearchParams(window.location.search);
			if (urlParams.size === 0) return;

			const orderDataParam = urlParams.get('orderdata');
			const successParam = urlParams.get('success');

			if (successParam && orderDataParam) {
				const decodedJSON = decodeURIComponent(orderDataParam.replace(/\+/g, ' '));
				try {
					const decodedOrderData = JSON.parse(decodedJSON);
					const userJson = JSON.parse(user || '{}');
					const orderHistory = await getOrderHistory(userJson.uid);
					const newHistory = orderHistory ? [...orderHistory.history, decodedOrderData] : [decodedOrderData];
					await placeOrder(userJson.uid, { history: newHistory });
					setCartData({ items: [], totalQuantity: 0, totalPrice: 0 });
					await updateCartData({ uid: userJson.uid, cart: { items: [], totalQuantity: 0, totalPrice: 0 } });

					enqueueSnackbar('Order placed successfully', { variant: 'success' });
					navigate('/completion?type=menuorder');
				} catch (error) {
					console.error('Error parsing JSON:', error);
				}
			} else if (!successParam) {
				enqueueSnackbar('Error placing order', { variant: 'error' });
				navigate('/cart');
			}
		};

		extractJSONFromURL();
	}, [enqueueSnackbar, navigate, user]);

	useInterval(() => {
		if (user) {
			fetchCartData();
		} else {
			setCartData([]);
		}
	}, 800);

	const fetchCartData = async () => {
		const userJson = JSON.parse(user || '{}');
		const cartData = await getCartData(userJson.uid);
		setCartData(cartData);
		const userData = await getUserData(userJson.uid);
		setUserData(userData);
	};

	const handlePlaceOrder = async (e: any) => {
		e.preventDefault();

		try {
			setLoading(true);
			const userJson = JSON.parse(user || '{}');
			const orderData = {
				uid: userJson.uid,
				order: {
					items: cartData.items,
					totalQuantity: cartData.totalQuantity,
					totalPrice: cartData.totalPrice,
					address: userData.address,
					email: userData.email,
					payment: true,
					cookingInstructions,
					createdAt: new Date(),
					deliveryCharges: 2
				}
			};

			const session = await POST('create-checkout-session', orderData);

			if (!stripe) {
				console.error('Stripe not loaded');
				enqueueSnackbar('Stripe not loaded', { variant: 'error' });
				return;
			}

			try {
				await stripe.redirectToCheckout({ sessionId: session.id });
			} catch (error) {
				console.error('Error placing order:', error);
				enqueueSnackbar('Network error, try again in sometime', { variant: 'info' });
			}
		} catch (error) {
			console.error('Error placing order:', error);
			enqueueSnackbar('Network error, try again in sometime', { variant: 'info' });
		} finally {
			setLoading(false);
		}
	};

	return (
		<section className='cart__section'>
			<div className='container'>
				<div className='row'>
					<div className='col-md-12'>
						<h2 className='text-white'>Your Cart</h2>
						<p className='text__gray__three'>
							View your item in your cart and proceed forward to make order
						</p>
						<Form onSubmit={handlePlaceOrder}>
							{isCartNotEmpty ? (
								<>
									<div className='row justify-content-center'>
										<div className='col-md-12'>
											<p className='text__green fw-bold'>
												Estimation Delivery time is between 12pm - 14pm
											</p>
										</div>
										<div className='col-md-7'>
											<CartItemList items={cartData.items} />
										</div>
										<div className='col-md-5'>
											<SpecialInstructions
												cookingInstructions={cookingInstructions}
												setCookingInstructions={setCookingInstructions}
											/>
											<DeliveryAddress userData={userData} />
											<PaymentSummary
												cartData={cartData}
												userData={userData}
												loading={loading}
											/>
										</div>
									</div>
								</>
							) : (
								<CardEmpty />
							)}
						</Form>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Cart;
