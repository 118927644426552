import { initializeApp } from 'firebase/app';
import { getStripePayments } from '@stripe/firestore-stripe-payments';
import { httpsCallable, getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import 'firebase/auth';

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);
const payments = getStripePayments(app, {
	productsCollection: 'carts',
	customersCollection: 'users'
});

// Connect your app to the Cloud Functions Emulator running on localhost for local testing
// When running locally, Cloud Functions will be available at http://localhost:5001/<projectId>/<region>/<functionName>
// Learn more about testing Cloud Functions locally: https://firebase.google.com/docs/emulator-suite/connect_functions
if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
	connectFunctionsEmulator(functions, '127.0.0.1', 5001);
}

const sendEmail = httpsCallable(functions, 'sendEmail');

export { app, firebaseConfig, payments, functions };
