import './App.scss';

// styles
import 'bootstrap/dist/js/bootstrap.bundle';
import 'react-phone-input-2/lib/style.css';

// providers
import { SnackbarProvider } from 'notistack';
import { RouterProvider } from 'react-router-dom';
import { LocaleProvider } from './contexts/locale-provider';
import router from './routes';

function App() {
	return (
		<SnackbarProvider
			maxSnack={3}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right'
			}}
			TransitionProps={{
				direction: 'left',
				timeout: 100
			}}
			autoHideDuration={2000}
		>
			<LocaleProvider>
				{/* @ts-ignore */}
				<RouterProvider router={router} />
			</LocaleProvider>
		</SnackbarProvider>
	);
}

export default App;
