import React from 'react';

interface PaymentSummaryProps{
    cartData: any;
    userData: any;
    loading: boolean;
}

const PaymentSummary = ({ cartData, userData, loading }:PaymentSummaryProps) => {
	return (
		<div className='card mt-4'>
			<div className='card-body'>
				<div className='row'>
					<div className='col-md-12'>
						<h4>Payment Summary</h4>
					</div>
				</div>
				<hr />
				<div className='row mt-4'>
					<div className='col-4'>
						<h6 className='text__gray__three fw-bol'>Delivery Charges</h6>
					</div>
					<div className='col-8 text-end'>
						<p className='fw-bold text__green'>€ 2</p>
					</div>
				</div>
				<div className='row'>
					<div className='col-4'>
						<h6 className='text__gray__three fw-bol'>Subtotal</h6>
					</div>
					<div className='col-8 text-end'>
						<p className='fw-bold text__green'>€ {(2 + cartData.totalPrice).toFixed(2)}</p>
					</div>
				</div>
				<div className='row'>
					<div className='col-4'>
						<h6 className='text__gray__three fw-bol'>Total Items</h6>
					</div>
					<div className='col-8 text-end'>
						<p className='fw-bold text__gray__three mb-0'>{cartData.totalQuantity}</p>
					</div>
				</div>
				<hr />
				<button
					type='submit'
					className='order__btn btn w-100'
					disabled={!userData?.addressLine1 || loading}
				>
					{loading ? (
						<div
							className='spinner-border text-light'
							role='status'
						>
							<span className='visually-hidden'>Loading...</span>
						</div>
					) : (
						'Place order'
					)}
				</button>
			</div>
		</div>
	);
};

export default PaymentSummary;
