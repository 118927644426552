import React from 'react';
import foodImage from '../../assets/foodImage1.jpg';
import foodImageOne from '../../assets/foodImage2.jpg';

const FoodImage = () => {
	return (
		<section>
			<div className='container-fluid'>
				<div className='row'>
					<div className='col-6 p-0'>
						<img
							src={foodImage}
							alt='cookOne'
							className='img-fluid'
						/>
					</div>
					<div className='col-6 p-0'>
						<img
							src={foodImageOne}
							alt='cookOne'
							className='img-fluid'
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default FoodImage;
