import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { incrementQuantity, decrementQuantity, removeItem } from '../../redux/cartSlice';
import food from '../../assets/food.png';
import '../../pages/cart/cart.scss';
import { getCartData, updateCartData } from '../../config/firestore/cartData';
import veganMeal from '../../assets/veganMeal.jpeg';
import lassi from '../../assets/lassi.jpeg';
import ratia from '../../assets/ratia.jpeg';
import roti from '../../assets/roti.jpeg';
import veganSubzi from '../../assets/vegan.jpeg';
import vegSubzi from '../../assets/veg.jpeg';

type Props = {
	id: number;
	title: string;
	price: number;
	quantity: number;
	menu: string;
	day: string;
	date: string;
	meal: string;
};

const CartItem = ({ id, title, price, quantity, menu, day, date, meal }: Props) => {
	const dispatch = useDispatch();
	const { user } = useSelector((state: any) => state.otp);

	const handleDecrementQuantity = async () => {
		dispatch(decrementQuantity(id));

		// get the cart data from firestore
		const userJson = JSON.parse(user || '{}');
		const cartData = await getCartData(userJson.uid);

		// find the item of id to update
		if (!cartData) return;

		const itemToUpdate = cartData.items.find((item: any) => item.id === id);
		itemToUpdate.quantity = itemToUpdate.quantity - 1;
		cartData.totalQuantity = cartData.totalQuantity - 1;
		cartData.totalPrice = cartData.totalPrice - price;

		// if the quantity is 1, remove the item
		if (itemToUpdate.quantity === 0) {
			const newItems = cartData.items.filter((item: any) => item.id !== id);
			cartData.items = newItems;
		}

		// update the cart data in firestore
		await updateCartData({
			uid: userJson.uid,
			cart: { items: cartData.items, totalQuantity: cartData.totalQuantity, totalPrice: cartData.totalPrice }
		});
	};

	const handleIncrementQuantity = () => {
		dispatch(incrementQuantity(id));

		// get the cart data from firestore
		const userJson = JSON.parse(user || '{}');
		getCartData(userJson.uid).then((cartData: any) => {
			// find the item of id to update
			if (!cartData) return;
			const itemToUpdate = cartData.items.find((item: any) => item.id === id);
			itemToUpdate.quantity = itemToUpdate.quantity + 1;
			cartData.totalQuantity = cartData.totalQuantity + 1;
			cartData.totalPrice = cartData.totalPrice + price;

			// update the cart data in firestore
			updateCartData({
				uid: userJson.uid,
				cart: { items: cartData.items, totalQuantity: cartData.totalQuantity, totalPrice: cartData.totalPrice }
			});
		});
	};

	const handleRemoveItem = (e: any) => {
		e.preventDefault();

		dispatch(removeItem(id));

		// get the cart data from firestore
		const userJson = JSON.parse(user || '{}');
		getCartData(userJson.uid).then((cartData: any) => {
			// find the item of id to remove
			if (!cartData) return;
			const itemToRemove = cartData.items.find((item: any) => item.id === id);
			cartData.totalQuantity = cartData.totalQuantity - itemToRemove.quantity;
			cartData.totalPrice = cartData.totalPrice - itemToRemove.price * itemToRemove.quantity;

			// remove the item from the cart
			const newItems = cartData.items.filter((item: any) => item.id !== id);
			cartData.items = newItems;

			// update the cart data in firestore
			updateCartData({
				uid: userJson.uid,
				cart: {
					...[],
					items: cartData.items,
					totalQuantity: cartData.totalQuantity,
					totalPrice: cartData.totalPrice
				}
			});
		});
	};

	const images = () => {
		if (meal === 'MEAL') return veganMeal;
		if (meal === 'LASSI') return lassi;
		if (meal === 'BOONDI RAITA') return ratia;
		if (meal === 'VEGAN SUBZI') return veganSubzi;
		if (meal === 'VEGETARIAN SUBZI') return vegSubzi;
		if (meal === 'EXTRA') return roti;
		if (meal === 'OPTIONAL') return food;
	};

	return (
		<div className='card mb-3 mt-4'>
			<div className='card-body py-1.5'>
				<div className='row align-items-center'>
					<div className='col-md-10'>
						<div className='row align-items-center'>
							<div className='col-md-2 pe-md-0'>
								<img
									src={images()}
									alt='Delicious meal'
									className='img-fluid d-block mx-auto'
									style={{
										width: '100px',
										height: '100px',
										borderRadius: '50%',
										border: '4px solid #b8b8b8'
									}}
								/>
							</div>
							<div className='col-md-10 text-md-start text-center mt-md-0 mt-3'>
								<p className='text-white mb-0 fw-bold'>
									{day} &nbsp;
									{date && <span className='text__gray__three'>({date})</span>}
								</p>
								<p className='text-white fw-bold mb-0'>{meal}</p>
								<p className='text__gray__three'>{menu}</p>
							</div>
						</div>
					</div>
					<div className='col-md-2'>
						<h6 className='text__green fw-bold text-md-end text-center'>€ {price.toFixed(2)}</h6>
					</div>
				</div>
				<div className='row mt-3'>
					<div className='col-md-12 d-flex align-items-center justify-content-between'>
						<div className='cartItem__actions d-flex align-items-center'>
							<button
								type='button'
								className='btn btn-outline-secondary px-2 py-0 text__gren'
								onClick={handleDecrementQuantity}
							>
								-
							</button>
							<span className='text__green mx-3'>{quantity}</span>
							<button
								type='button'
								className='btn btn-outline-secondary px-2 py-0 text__gren'
								onClick={handleIncrementQuantity}
							>
								+
							</button>
						</div>
						<button
							className='text-danger fw-bold text-end text-decoration-none cursor-pointer border-0 bg-transparent'
							onClick={handleRemoveItem}
						>
							Remove from cart
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CartItem;
