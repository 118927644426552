import React from 'react';
import noCartIcon from '../../assets/noCart.png';

const OrderEmpty = () => {
	return (
		<section className='cartempty__section'>
			<div className='row'>
				<div className='row'>
					<div className='col-md-12'>
						<img
							src={noCartIcon}
							alt='noCartIcon'
							className='img-fluid'
						/>
						<p className='text__gray__three'>No item in the cart!</p>
					</div>
				</div>
			</div>
		</section>
	);
};

export default OrderEmpty;
