import './our-services.scss';
import tava from '../../assets/tava1.jpg';
import { useIntl } from 'react-intl';

const OurServices = () => {
	const { formatMessage } = useIntl();
	return (
		<section
			className='services__section'
			id='our-services'
		>
			<div className='container-fluid h-100'>
				<div className='row d-flex align-items-center h-100'>
					<div className='col-md-5 offset-md-1 mt-md-0 mt-5 ps-lg-1'>
						<button className='tags__primary'>{formatMessage({ id: 'navbar.our.services' })}</button>
						<h1 className='text-white mt-3'>{formatMessage({ id: 'service.header' })}</h1>
						<h2 className='text__orange'>{formatMessage({ id: 'service.sub.header' })}</h2>
						<p className='text__gray__three'>
							{formatMessage({ id: 'service.description' })} <br />
							{formatMessage({ id: 'service.descriptionTwo' })} <br />
							{formatMessage({ id: 'service.descriptionThree' })}
						</p>
					</div>
					<div className='col-md-6'>
						<img
							src={tava}
							alt='cooking'
							className='img-fluid'
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default OurServices;
