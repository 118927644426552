import React from 'react';
import MenuListCard from './MenuListCard';
import '../../pages/menu/menu.scss';
import { newMenuItems as newMenu } from '../../data/new-repeated-menu';
import { orderNotAvailableOnDates } from '../../data/menu-available';

interface MenuListProps {
	date: string;
}

interface MenuItem {
	id: number;
	type: string;
	day: string;
	menu: string;
	price: number;
	meal: string;
}

const MenuList: React.FC<MenuListProps> = ({ date }) => {
	const filteredMenuItems = newMenu.find((item: any) => item.date === date);
	const menuItems = filteredMenuItems?.items || [];

	const menuAvailable = !orderNotAvailableOnDates.includes(date);

	const veganMenus = menuItems.filter(menu => menu.type === 'VEGAN');
	const vegetarianMenus = menuItems.filter(menu => menu.type === 'VEGETARIAN');
	const optionalMenus = menuItems.filter(menu => menu.type === 'OPTIONAL');
	const extraMenus = menuItems.filter(menu => menu.type === 'EXTRA');

	return (
		<section className='menuList__section mt-md-5'>
			<div className='container'>
				<div className='row justify-content-center'>
					{renderMenuSection('VEGAN MENU', veganMenus, date, menuItems, menuAvailable)}
					{renderMenuSection('VEGETARIAN MENU', vegetarianMenus, date, menuItems, menuAvailable)}
				</div>
				<div className='row justify-content-center'>
					<h5 className='text__green fw-bolder mt-5 text-center'>DAILY MENU</h5>
					<p className='text-white mb-0 fw-bold text-center mb-3'>
						{menuItems[0]?.day} {date && <span className='text__gray__three'>({date})</span>}
					</p>
					{renderMenuSection('OPTIONAL MENU', optionalMenus, date, menuItems, menuAvailable)}
					{renderMenuSection('EXTRA MENU', extraMenus, date, menuItems, menuAvailable)}
				</div>
			</div>
		</section>
	);
};

const renderMenuSection = (
	title: string,
	items: MenuItem[],
	date: string,
	menuItems: MenuItem[],
	menuAvailable: boolean
) => (
	<div className='col-md-5'>
		<h5 className='text__green fw-bolder text-center mt-3'>{title}</h5>
		<p className='text-white mb-0 fw-bold text-center mb-3'>
			{menuItems[0]?.day} {date && <span className='text__gray__three'>({date})</span>}
		</p>
		{items.map(item => (
			<MenuListCard
				key={item.id}
				id={item.id}
				type={item.type}
				day={item.day}
				menu={item.menu}
				price={item.price}
				date={date}
				meal={item.meal}
				menuAvailable={menuAvailable}
			/>
		))}
	</div>
);

export default MenuList;
