import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './login.scss';
import '../../Common/Button.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { auth } from '../../redux/actions';
import { addUserDetails } from '../../redux/authSlice';
import { storeUserData } from '../../config/firestore/userData';
import { formatUserData } from '../../utils/user-utils';

import PhoneInput from '../../sections/login/phn-input';
import OtpInput from '../../sections/login/otp-input';
import GoogleSignInButton from '../../sections/login/google-signin-btn';
import Separator from '../../sections/login/separator';
import { ConfirmationResult, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';

const Login = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const { isAuthenticated } = useSelector((state: any) => state.otp);
	const [otpReceived, setOtpReceived] = useState<boolean>(false);
	const [phone, setPhone] = useState('');
	const [otp, setOtp] = useState('');
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [isVerifyButtonDisabled, setIsVerifyButtonDisabled] = useState(false);
	const [otpTimeout, setOtpTimeout] = useState(60);
	const [confirmation, setConfirmation] = useState<ConfirmationResult>();

	useEffect(() => {
		if (isAuthenticated) {
			navigate('/menu');
		}
	}, [isAuthenticated, navigate]);

	useEffect(() => {
		if (otpTimeout > 0) {
			const intervalId = setInterval(() => {
				setOtpTimeout(prevTimeout => prevTimeout - 1);
			}, 1000);
			return () => clearInterval(intervalId);
		}
	}, [otpTimeout]);

	const handlePhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		if (/^\d{0,11}$/.test(value)) {
			setPhone(value);
		}
	};

	const sendOTP = async () => {
		if (phone === '') {
			enqueueSnackbar('Phone number is empty', { variant: 'error' });
			return;
		}

		if (phone.length < 11) {
			enqueueSnackbar('Please enter a valid phone number', { variant: 'error' });
			return;
		}

		if (isButtonDisabled) {
			return;
		}

		try {
			setIsButtonDisabled(true);
			const recaptcha = new RecaptchaVerifier(auth, 'recaptcha', { size: 'invisible' });

			const confirmation = await signInWithPhoneNumber(auth, '+49' + phone, recaptcha);
			setConfirmation(confirmation);
			enqueueSnackbar('OTP sent successfully', { variant: 'success' });
			setOtpReceived(true);
		} catch (error: any) {
			handleError(error);
		} finally {
			setIsButtonDisabled(false);
		}
	};

	const verifyOTP = async () => {
		if (isVerifyButtonDisabled) {
			return;
		}

		setIsVerifyButtonDisabled(true);

		try {
			const result = await confirmation?.confirm(otp);
			if (result && result.user) {
				dispatch(addUserDetails(JSON.stringify(result.user)));
			}
			enqueueSnackbar('OTP verified successfully', { variant: 'success' });

			if (result && result.user) {
				const userData = formatUserData(result.user);

				if (result.user.metadata.creationTime === result.user.metadata.lastSignInTime) {
					await storeUserData(userData);
					navigate('/profile');
				} else {
					navigate('/menu');
				}
			}
		} catch (error: any) {
			handleError(error);
		} finally {
			setIsVerifyButtonDisabled(false);
		}
	};

	const handleError = (error: any) => {
		switch (error.code) {
			case 'auth/invalid-verification-code':
				enqueueSnackbar('The verification code is invalid.', { variant: 'error' });
				break;
			case 'auth/code-expired':
				enqueueSnackbar('The verification code is expired.', { variant: 'error' });
				break;
			default:
				enqueueSnackbar('Something went wrong. Please try again later.', { variant: 'error' });
				break;
		}
	};

	return (
		<section className='mt-5 pt-5 login__section'>
			<div className='container pt-5'>
				<div className='row'>
					<div className='col-md-12 text-center'>
						<h1 className='text-white'>
							Welcome to <span className='text__green'>Kadamba food</span>
						</h1>
					</div>
				</div>
				<div className='row justify-content-center mt-4'>
					<div className='col-md-5 text-center'>
						<div className='card'>
							<div className='card-body'>
								<PhoneInput
									phone={phone}
									handlePhoneChange={handlePhoneChange}
									sendOTP={sendOTP}
									isButtonDisabled={isButtonDisabled}
									otpReceived={otpReceived}
								/>
								{otpReceived && (
									<OtpInput
										otp={otp}
										setOtp={setOtp}
										verifyOTP={verifyOTP}
										isVerifyButtonDisabled={isVerifyButtonDisabled}
										resendOTP={sendOTP}
										otpTimeout={otpTimeout}
									/>
								)}
								<Separator />
								<GoogleSignInButton />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div id='recaptcha'></div>
		</section>
	);
};

export default Login;
