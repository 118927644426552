
// @ts-ignore
import OTPInput from 'otp-input-react'; // this module has no typescript support

interface OtpInputProps {
	otp: string;
	setOtp: (otp: string) => void;
	verifyOTP: () => void;
	isVerifyButtonDisabled: boolean;
	resendOTP: () => void;
	otpTimeout: number;
}

const OtpInputComponent = ({ otp, setOtp, verifyOTP, isVerifyButtonDisabled, resendOTP, otpTimeout }: OtpInputProps) => (
	<div className='row mt-4 otp-subcontainer'>
		<div className='col-md-12 text-start'>
			<label
				htmlFor='otpInput'
				className='form-label'
			>
				Enter Your OTP
			</label>
		</div>
		<div className='col-md-12'>
			<form>
				<div className='mb-3'>
					<OTPInput
						id='otpInput'
						value={otp}
						onChange={setOtp}
						OTPLength={6}
						otpType='number'
						disabled={false}
						autoFocus
					/>
				</div>
				<div className='mt-2 d-flex justify-content-between align-items-baseline'>
					<div className='d-flex flex-column'>
						<button
							type='button'
							className='btn cart__btn__outline'
							disabled={otpTimeout > 0}
							onClick={resendOTP}
						>
							{otpTimeout > 0 ? `Resend (${otpTimeout})` : 'Resend'}
						</button>
						{otpTimeout > 0 && <small className='text-white mt-1'>{otpTimeout} seconds remaining</small>}
					</div>
					<button
						type='button'
						className='btn order__btn'
						disabled={isVerifyButtonDisabled}
						onClick={verifyOTP}
					>
						{isVerifyButtonDisabled ? 'Checking...' : 'Verify OTP'}
					</button>
				</div>
			</form>
		</div>
	</div>
);

export default OtpInputComponent;
