import googleIcon from '../../assets/google.png';
import { useSnackbar } from 'notistack';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useDispatch } from 'react-redux';
import { auth } from '../../redux/actions';
import { addUserDetails } from '../../redux/authSlice';
import { storeUserData, userExists } from '../../config/firestore/userData';
import { useNavigate } from 'react-router-dom';
import { formatUserData } from '../../utils/user-utils';

const GoogleSignInButton = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();

	const handleGoogleSignIn = () => {
		const provider = new GoogleAuthProvider();
		provider.setCustomParameters({ prompt: 'select_account' });

		signInWithPopup(auth, provider)
			.then(async result => {
				const exists = await userExists(result.user.uid);
				const userData = formatUserData(result.user);

				if (!exists) {
					await storeUserData(userData);
					navigate('/profile');
				} else {
					navigate('/menu');
				}

				dispatch(addUserDetails(JSON.stringify(result.user)));
				enqueueSnackbar('Logged in successfully', { variant: 'success' });
			})
			.catch(error => {
				enqueueSnackbar('Error logging in', { variant: 'error' });
				console.error('Error logging in', error);
			});
	};

	return (
		<button
			className='btn google__btn__outline w-100'
			onClick={handleGoogleSignIn}
		>
			<p className='mb-0'>
				<img
					src={googleIcon}
					alt='Google Icon'
					className='img-fluid mx-2'
				/>
				Login with Google
			</p>
		</button>
	);
};

export default GoogleSignInButton;
