import { User } from 'firebase/auth';
import { UserType } from '../types';

export const formatUserData = (user: User): UserType => {
	const displayNameParts = user.displayName?.split(' ') || ['', ''];
	return {
		uid: user.uid,
		phoneNumber: user.phoneNumber || '',
		email: user.email || '',
		bellNumber: '',
		addressLine1: '',
		city: '',
		state: '',
		postalCode: '',
		displayName: user.displayName || '',
		fname: displayNameParts[0],
		lname: displayNameParts[1],
		photoURL: user.photoURL || '',
		isSubscribed: false
	};
};
