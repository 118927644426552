import { useEffect, useState } from 'react';
import './all-orders.scss';
import { useSelector } from 'react-redux';
import { fetchOrdersWithUsers } from '../../config/firestore/allOrder';
import { Table } from 'react-bootstrap';
import moment from 'moment';
import { Calendar } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { LooseValue, Value } from 'react-calendar/dist/cjs/shared/types';

const OrdersTable = () => {
	const { user } = useSelector((state: any) => state.otp);
	const [orders, setAllOrders] = useState<any>([]);
	const [searchQuery, setSearchQuery] = useState<string>('');
	const [selectedDate, setSelectedDate] = useState<string>(moment().add(1, 'day').format('DD-MMM-YYYY'));
	const [showCalendar, setShowCalendar] = useState<boolean>(false);
	const [calenderDate, setCalenderDate] = useState<Date | LooseValue>(new Date());

	console.log('calender date: ', calenderDate)

	useEffect(() => {
		const fetchData = async () => {
			try {
				if (user) {
					const userDoc = await fetchOrdersWithUsers();
					setAllOrders(userDoc);
					console.log('userDoc:', userDoc);
				}
			} catch (error) {
				console.log(error);
			}
		};
		fetchData();
	}, [user]);

	// filter orders based on search query and selected date
	const filterOrders = () => {
		let filteredOrders = orders.flat();
		if (searchQuery) {
			filteredOrders = filteredOrders.filter((order: any) =>
				order.user.displayName.toLowerCase().includes(searchQuery.toLowerCase())
			);
		}
		if (selectedDate) {
			filteredOrders = filteredOrders.filter(
				(order: any) => moment(order.order.items[0].date).format('DD-MMM-YYYY') === selectedDate
			);
		}
		return filteredOrders;
	};

	// getting the unique dates from order data
	const uniqueDates: string[] = [
		...new Set<string>(orders.flat().map((order: any) => moment(order.order.createdAt).format('DD-MMM-YYYY')))
	];

	return (
		<div className='allorders__container mt-4'>
			<div className='container-fluid mt-5'>
				<div className='row justify-content-center'>
					<div className='col-md-12'>
						<div className='row '>
							<div className='col-md-6'>
								<input
									type='text'
									className='form-control mb-3'
									placeholder='Search by user name'
									value={searchQuery}
									onChange={e => setSearchQuery(e.target.value)}
								/>
							</div>
							<div className='col-md-6 text-md-end text-center d-flex flex-column align-items-md-end'>
								<button
									className='order__btn btn w-15'
									onClick={() => setShowCalendar(!showCalendar)}
								>
									{showCalendar ? 'Hide Calendar' : 'Show Calendar'}
								</button>
								{showCalendar && (
									<Calendar
										onChange={(date: Value) => {
											const formattedDate = moment(date as Date).format('DD-MMM-YYYY');
											setSelectedDate(formattedDate);
										}}
										value={calenderDate}
									/>
								)}
							</div>
						</div>
						<div className='row'>
							<div className='col-md-12'>
								<h3 className='text__green'>Delivery date order list for - {selectedDate} </h3>
							</div>
						</div>
						<div className='row mt-5'>
							<div className='col-md-12'>
								<Table
									striped
									bordered
									hover
									responsive='sm'
									className='table-dark'
								>
									<thead>
										<tr>
											<th>Sr. No.</th>
											<th>User</th>
											<th>Phone No.</th>
											<th>Email</th>
											<th>Total Price</th>
											<th>Payment</th>
											<th>Bell Name</th>
											<th>Address</th>
											<th>Subscription</th>
											<th>Spice level</th>
											<th>Items</th>
										</tr>
									</thead>
									<tbody>
										{filterOrders().length === 0 ? (
											<tr>
												<td
													colSpan={11}
													className='text-center'
												>
													No matching orders found
												</td>
											</tr>
										) : (
											filterOrders().map((order: any, index: any) => (
												<tr key={index}>
													<td>{index + 1}</td>
													<td>
														<div className='d-flex align-items-center'>
															<span>
																{order.user.fname} {order.user.lname}
															</span>
														</div>
													</td>
													<td>{order.user.phoneNumber}</td>
													<td>{order.user.email}</td>
													<td>€{order.order.totalPrice.toFixed(2)}</td>
													<td>{order.order.payment ? 'Paid' : 'Pending'}</td>
													<td>{order.user.bellNumber} </td>
													<td>
														{order.user.addressLine1} {order.user.postalCode}
													</td>
													<td>
														{order.user.isSubscribed ? (
															<div className='badge bg-success text-white'>
																Subscribed
															</div>
														) : (
															<div className='badge bg-danger text-white'>
																Not Subscribed
															</div>
														)}
													</td>
													<td>{order.order.cookingInstructions || 'None'}</td>
													<td>
														<Table
															striped
															bordered
															hover
															size='sm'
															className='table-dark'>
															<thead>
																<tr>
																	<th>Delivery Date</th>
																	<th>Menu</th>
																	<th>Type</th>
																	<th>Quantity</th>
																	<th>Meal</th>
																	<th>Price</th>
																</tr>
															</thead>
															<tbody>
																{order.order.items.map((item: any, idx: number) => (
																	<tr key={idx}>
																		<td>{item.date}</td>
																		<td>{item.menu}</td>
																		<td>{item.type}</td>
																		<td>{item.meal}</td>
																		<td>{item.quantity}</td>
																		<td>€{item.price.toFixed(2)}</td>
																	</tr>
																))}
															</tbody>
														</Table>
													</td>
												</tr>
											))
										)}
									</tbody>
								</Table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OrdersTable;
