import { useState, useEffect } from 'react';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { GET } from '../api';

const useStripe = () => {
	const [stripe, setStripe] = useState<Stripe | null>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchStripeConfig = async () => {
            try {
                const { publishableKey } = await GET('stripe-config');
                const stripeInstance = await loadStripe(publishableKey);
                setStripe(stripeInstance);
            } catch (error: unknown) {
                setError(error as string);
            }
        };

        fetchStripeConfig();
    }, []);

	return { stripe, error };
};

export default useStripe;
