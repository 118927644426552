import React, { useEffect, useState } from 'react';
import './Orders.scss';
import { getOrderHistory } from '../../config/firestore/orders';
import { getSubscriptionHistory } from '../../config/firestore/subscription';
import { getUserData } from '../../config/firestore/userData';
import { OrderHistoryType, UserType, OrderType, SubscriptionHistoryType } from '../../types';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { DocumentData } from 'firebase/firestore';
import food from '../../assets/food.png';
import OrderEmpty from './OrderEmpty';
import { TagGreen } from '../../Common/Tags';

type Props = {};

const Orders = (props: Props) => {
	const [orderHistory, setOrderHistory] = useState<OrderHistoryType | DocumentData | null>(null);
	const [subscriptionOrderHistory, setSubscriptionOrderHistory] = useState<
		SubscriptionHistoryType | DocumentData | null
	>(null);
	const [userData, setUserData] = useState<UserType | DocumentData | null>(null);
	const { user } = useSelector((state: any) => state.otp);
	const [dateFilter, setDateFilter] = useState(moment().format('MMM YYYY'));

	useEffect(() => {
		const fetchData = async () => {
			if (user) {
				const userJson = JSON.parse(user);
				const userDoc = await getUserData(userJson.uid);
				setUserData(userDoc);
				const orderHistoryDoc = await getOrderHistory(userJson.uid);
				setOrderHistory(orderHistoryDoc);
				const subscriptionOrderHistoryDoc = await getSubscriptionHistory(userJson.uid);
				setSubscriptionOrderHistory(subscriptionOrderHistoryDoc);
			} else {
				setUserData(null);
				setOrderHistory(null);
				setSubscriptionOrderHistory(null);
			}
		};
		fetchData();
	}, [user]);

	const handleFilter = (order: OrderType) => {
		const formattedDate = moment(order.createdAt).format('MMM YYYY');
		setDateFilter(formattedDate);
	};

	useEffect(() => {
		const currentMonth = moment().format('MMM YYYY');
		const currentMonthOrder = orderHistory?.history.find(
			(order: { createdAt: moment.MomentInput }) => moment(order.createdAt).format('MMM YYYY') === currentMonth
		);

		if (currentMonthOrder) {
			handleFilter(currentMonthOrder);
		}
	}, [orderHistory?.history]);
	return (
		<div className='order__section'>
			<div className='container'>
				<div className='col-md-12'>
					<h2 className='text-white'>My Orders</h2>
					<p className='text__gray__three'>View your order history month-wise</p>
					{subscriptionOrderHistory &&
						subscriptionOrderHistory?.history.map((history: any) => (
							<div className='row'>
								<div className='col-md-12'>
									<div className='card'>
										<div className='card-body'>
											<div className='row'>
												<div className='col-md-6 text-md-start text-center'>
													<TagGreen title='Subscription' />
													<TagGreen title={history.subscriptionFrequency} />
												</div>
												<div className='col-md-6 text-md-end text-center mt-md-0 mt-4'>
													<p className='mb-0'>
														End On - <span className='text__green'>{history.endDate}</span>
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						))}

					{userData && orderHistory ? (
						<div className='mt-5'>
							<div className='dropdown'>
								<button
									className='btn dropdown-toggle d-flex justify-content-center px-3 py-2'
									type='button'
									id='dropdownMenuButton'
									data-bs-toggle='dropdown'
									aria-expanded='false'
								>
									{moment(dateFilter).format('MMM YYYY')}
								</button>
								<ul
									className='dropdown-menu'
									aria-labelledby='dropdownMenuButton'
								>
									{orderHistory &&
										orderHistory.history.map(
											(order: OrderType, index: React.Key | null | undefined) => (
												<li key={index}>
													<button
														className={`dropdown-item ${
															moment(order.createdAt).format('MMM YYYY') ===
															moment(dateFilter).format('MMM YYYY')
																? 'active'
																: 'text__gray__three'
														}`}
														onClick={() => handleFilter(order)}
														style={{
															backgroundColor:
																moment(order.createdAt).format('MMM YYYY') ===
																moment(dateFilter).format('MMM YYYY')
																	? 'var(--active-border-color)'
																	: 'transparent',
															fontSize: '1rem'
														}}
													>
														{moment(order.createdAt).format('MMM YYYY')}
													</button>
												</li>
											)
										)}
								</ul>
							</div>

							{orderHistory &&
								orderHistory.history
									.filter((order: OrderType) => {
										if (dateFilter === '') {
											return true;
										}
										// @ts-ignore
										return moment(order.createdAt).format('MMM YYYY') === dateFilter;
									})
									.map((order: any, index: number) => (
										<div className='row mt-4'>
											<div className='col-md-12'>
												{order?.order?.items.map((item: any, index: number) => (
													<div className='card mb-3'>
														<div className='card-body py-1.5'>
															<div className='row align-items-center'>
																<div className='col-md-8'>
																	<div className='row align-items-center'>
																		<div className='col-md-1 pe-md-0'>
																			<img
																				src={food}
																				alt='Delicious meal'
																				className='img-fluid d-block mx-auto'
																			/>
																		</div>
																		<div className='col-md-11 text-md-start text-center mt-md-0 mt-3'>
																			<p className='text-white mb-0 fw-bold'>
																				{item.type}
																			</p>
																			<p className='text-white mb-0 fw-bold'>
																				{item.day}{' '}
																				{item.date && (
																					<span className='text__gray__three'>
																						({item.date})
																					</span>
																				)}
																			</p>
																			<p className='text__gray__three'>
																				{item.menu}
																			</p>
																		</div>
																	</div>
																</div>
																<div className='col-md-4 text-md-end'>
																	<h6 className='text__green fw-bold text-md-end text-center'>
																		€ {item.price.toFixed(2)}
																	</h6>
																</div>
															</div>
														</div>
													</div>
												))}
											</div>
										</div>
									))}
						</div>
					) : (
						<OrderEmpty />
					)}
				</div>
			</div>
		</div>
	);
};

export default Orders;
