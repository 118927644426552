import React, { useEffect, useRef, useState } from 'react';
import './profile.scss';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { getUserData, storeUserData } from '../../config/firestore/userData';
import { getSubscriptionHistory } from '../../config/firestore/subscription';
import { TagGreen } from '../../Common/Tags';
import { validPostalCodes } from '../../data/postalCode';
import { SubscriptionHistoryType, UserType } from '../../types';
import { DocumentData } from 'firebase/firestore';

const Profile = () => {
	const { enqueueSnackbar } = useSnackbar();
	const { user } = useSelector((state: any) => state.otp);
	const [userData, setUserData] = useState<UserType | DocumentData | null>(null);
	const [editable, setEditable] = useState<boolean>(false);
	const [, setNewImage] = useState<string>('');
	const [, setUploadedFileName] = useState<string | null>(null);
	const [subscriptionOrderHistory, setSubscriptionOrderHistory] = useState<
		SubscriptionHistoryType | DocumentData | null
	>(null);
	const [showPostalModal, setShowPostalModal] = useState<boolean>(false);

	const navigate = useNavigate();

	useEffect(() => {
		const fetchData = async () => {
			if (user) {
				const userJson = JSON.parse(user);
				const userData = await getUserData(userJson.uid);
				setUserData(userData);
				const subscriptionOrderHistoryDoc = await getSubscriptionHistory(userJson.uid);
				setSubscriptionOrderHistory(subscriptionOrderHistoryDoc);
			}
		};
		fetchData();
	}, [user]);

	const handleEdit = () => {
		setEditable(!editable);
	};

	const handleSave = async () => {
		if (userData && 'postalCode' in userData && !validPostalCodes.includes(userData.postalCode)) {
			enqueueSnackbar('We do not deliver to this address', { variant: 'error' });
			return;
		}

		try {
			setEditable(false);

			const { fname, lname } = userData as UserType;
			const displayName = `${fname} ${lname}`;
			setUserData(prevData => ({
				...prevData,
				displayName
			}));

			if (userData && 'uid' in userData) {
				await storeUserData(userData as UserType);
				enqueueSnackbar('Your data updated successfully', { variant: 'success' });
				navigate('/menu');
			}

			setNewImage('');
			setUploadedFileName(null);
		} catch (error) {
			enqueueSnackbar('Error saving user data', { variant: 'error' });
		}
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setUserData(prevData => ({
			...prevData,
			[name]: value
		}));
	};

	const togglePostalModal = () => setShowPostalModal(!showPostalModal);

	return (
		<section className='profile__section'>
			<div className='container'>
				<div className='row'>
					<div className='col-md-12'>
						<a
							href='/menu'
							className='text__green text-end d-md-none d-flex justify-content-end'
						>
							Go to menu
						</a>
					</div>
				</div>
				<div className='row justify-content-center mt-2'>
					<div className='col-md-6'>
						<div className='card mb-5'>
							<div className='card-body'>
								<h4 className='text-center mb-4'>Please help us with your details</h4>
								<p className='text__gray text-center mb-4'>
									This will enable us to personalize your order by addressing you by name and to send
									you offers or important updates via your email address.
								</p>
								<div className='text-center mb-4'>
									{!editable && (
										<button
											type='button'
											className='btn order__btn w-100 mt-4'
											onClick={handleEdit}
										>
											Edit your profile
										</button>
									)}
									<div className='d-flex flex-column align-items-center'>
										{userData?.isSubscribed && <TagGreen title='Subscribed' />}
										{subscriptionOrderHistory?.history.map((history: any, index: number) => (
											<p
												className='mt-3'
												key={index}
											>
												End On - <span className='text__green'>{history.endDate}</span>
											</p>
										))}
									</div>
								</div>
								<form className='profile__form'>
									<div className='row'>
										<div className='col-md-6'>
											<div className='mb-3'>
												<label
													htmlFor='fname'
													className='form-label'
												>
													First Name
												</label>
												<input
													id='fname'
													type='text'
													className='form-control'
													placeholder='Enter first name'
													name='fname'
													value={userData?.fname || ''}
													readOnly={!editable}
													onChange={handleChange}
												/>
											</div>
										</div>
										<div className='col-md-6'>
											<div className='mb-3'>
												<label
													htmlFor='lname'
													className='form-label'
												>
													Last Name
												</label>
												<input
													id='lname'
													type='text'
													className='form-control'
													placeholder='Enter last name'
													name='lname'
													value={userData?.lname || ''}
													readOnly={!editable}
													onChange={handleChange}
												/>
											</div>
										</div>
									</div>
									<div className='mb-3'>
										<label
											htmlFor='email'
											className='form-label'
										>
											Email
										</label>
										<input
											id='email'
											type='email'
											className='form-control'
											placeholder='Enter email'
											name='email'
											value={userData?.email || ''}
											readOnly={!editable}
											onChange={handleChange}
										/>
									</div>
									<div className='mb-3'>
										<label
											htmlFor='phoneNumber'
											className='form-label'
										>
											Phone
										</label>
										<div className='input-group'>
											<span className='input-group-text'>+49</span>
											<input
												id='phoneNumber'
												type='tel'
												className='form-control'
												placeholder='Enter phone number'
												name='phoneNumber'
												value={userData?.phoneNumber || ''}
												readOnly={!editable}
												onChange={e => {
													const re = /^\d{0,11}$/;
													if (e.target.value === '' || re.test(e.target.value)) {
														handleChange(e);
													}
												}}
											/>
										</div>
									</div>
									<div className='mb-3'>
										<label
											htmlFor='bellNumber'
											className='form-label'
										>
											Bell Number
										</label>
										<input
											id='bellNumber'
											type='text'
											className='form-control'
											placeholder='Enter Bell Number'
											name='bellNumber'
											value={userData?.bellNumber || ''}
											readOnly={!editable}
											onChange={handleChange}
										/>
									</div>
									<div className='mb-3'>
										<label
											htmlFor='addressLine1'
											className='form-label'
										>
											Address Line 1
										</label>
										<input
											id='addressLine1'
											type='text'
											className='form-control'
											placeholder='Enter address'
											name='addressLine1'
											value={userData?.addressLine1 || ''}
											readOnly={!editable}
											onChange={handleChange}
										/>
									</div>
									<div className='mb-3'>
										<label
											htmlFor='city'
											className='form-label'
										>
											City
										</label>
										<input
											id='city'
											type='text'
											className='form-control'
											placeholder='Enter city'
											name='city'
											value={userData?.city || ''}
											readOnly={!editable}
											onChange={handleChange}
										/>
									</div>
									<div className='mb-3'>
										<label
											htmlFor='state'
											className='form-label'
										>
											State
										</label>
										<input
											id='state'
											type='text'
											className='form-control'
											placeholder='Enter State/Province/Region'
											name='state'
											value={userData?.state || ''}
											readOnly={!editable}
											onChange={handleChange}
										/>
									</div>
									<div className='mb-3'>
										<label
											htmlFor='postalCode'
											className='form-label'
										>
											Postal Code
										</label>
										<input
											id='postalCode'
											type='text'
											className='form-control'
											placeholder='Enter Postal Code'
											name='postalCode'
											value={userData?.postalCode || ''}
											readOnly={!editable}
											onChange={handleChange}
										/>
										<div
											className='text-end fw-lighter'
											style={{ fontSize: '12px' }}
										>
											<button
												className='bg-transparent text__green border-0'
												type='button'
												onClick={togglePostalModal}
											>
												Check postal code delivery range
											</button>
										</div>
									</div>
									{editable && (
										<button
											type='button'
											className='btn order__btn w-100 mt-4'
											onClick={handleSave}
										>
											Save
										</button>
									)}
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Modal
				show={showPostalModal}
				onHide={togglePostalModal}
			>
				<Modal.Header closeButton>
					<Modal.Title>Deliverable Postal Codes</Modal.Title>
				</Modal.Header>
				<Modal.Body>{validPostalCodes.join(', ')}</Modal.Body>
			</Modal>
		</section>
	);
};

export default Profile;
