import './about-us.scss';
import about from '../../assets/about1.jpg';
import { TagGreen } from '../../Common/Tags';
import { useIntl } from 'react-intl';
import React from 'react';

const AboutUs = () => {
	const { formatMessage } = useIntl();
	const description = formatMessage({ id: 'about.us.description' })
		.split('<br />')
		.map((text, index) => (
			<React.Fragment key={index}>
				{text}
				<br />
			</React.Fragment>
		));
	return (
		<section
			className='about__section'
			id='about-us'
		>
			<div className='container-fluid'>
				<div className='row d-flex align-items-center'>
					<div
						className='col-12 col-md-6 p-0 d-flex align-items-center justify-content-center'
						style={{ overflow: 'hidden' }}
					>
						<img
							src={about}
							alt='about'
							className='img-fluid'
						/>
					</div>
					<div className='col-12 col-md-5 ms-md-4 mt-md-0 mt-5'>
						<TagGreen title={formatMessage({ id: 'navbar.about.us' })} />
						<h1 className='mt-3 fw-bold'>{formatMessage({ id: 'about.us.header' })}</h1>
						<p className='text-gray-secondary'>{description}</p>
					</div>
				</div>
			</div>
		</section>
	);
};

export default AboutUs;
