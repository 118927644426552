import { useIntl } from 'react-intl';

const Info = () => {
	const { formatMessage } = useIntl();

	const renderStrongText = (id: string) => <strong className='text__green'>{formatMessage({ id })} </strong>;

	return (
		<section className='info__section'>
			<div className='container'>
				<p className='text__gray__three text-center mt-4'>
					{formatMessage({ id: 'contact.us.food.truck.description' })}{' '}
					{renderStrongText('contact.us.food.truck.description.day')}
					{formatMessage({ id: 'contact.us.food.truck.description.at' })}{' '}
					{renderStrongText('contact.us.food.truck.description.location')}
					{formatMessage({ id: 'contact.us.food.truck.description.market' })}{' '}
					{formatMessage({ id: 'contact.us.food.truck.description.from' })}{' '}
					{renderStrongText('contact.us.food.truck.description.time')}
					<br />
					{formatMessage({ id: 'contact.us.food.truck.description.every' })}{' '}
					{renderStrongText('contact.us.food.truck.description.day2')}
					{formatMessage({ id: 'contact.us.food.truck.description.at' })}{' '}
					{renderStrongText('contact.us.food.truck.description.location2')}
					{formatMessage({ id: 'contact.us.food.truck.description.from' })}{' '}
					{renderStrongText('contact.us.food.truck.description.time2')}
				</p>
				<p className='text__gray__three text-center mt-4'>
					{formatMessage({ id: 'contact.us.food.truck.descriptionTwo' })}
				</p>
			</div>
		</section>
	);
};

export default Info;
