import React from 'react';

const DeliveryAddress = ({ userData }: { userData: any }) => {
	return (
		<div
			className={`card mt-4 ${!userData?.addressLine1 || !userData.city || !userData.state || !userData.postalCode ? 'border-danger' : ''}`}
		>
			<div className='card-body'>
				<div className='row'>
					{userData?.addressLine1 ? (
						<>
							<div className='col-md-12 d-flex justify-content-between'>
								<h4>Your delivery address</h4>
								<a
									href='/profile'
									className='text__green fw-bold text-end text-decoration-none cursor-pointer border-0 bg-transparent'
								>
									Change
								</a>
							</div>
							<p>
								Address: {userData.addressLine1} {userData.city} {userData.state} {userData.postalCode}
							</p>
						</>
					) : (
						<>
							<div className='col-md-12 d-flex justify-content-between'>
								<h4>Your delivery address</h4>
								<a
									href='/profile'
									className='text__green fw-bold text-end text-decoration-none cursor-pointer border-0 bg-transparent'
								>
									Go to Profile
								</a>
							</div>
							<small className='text__gray__three'>Please add your address in profile</small>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default DeliveryAddress;
