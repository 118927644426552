import React from 'react';
import { useIntl } from 'react-intl';
import './foodTruck.scss';
import Info from '../payment/Info';

const Foodtruck = () => {
	const { formatMessage } = useIntl();

	const dates = [
		{ day1: '30.11', day2: '01.12' },
		{ day1: '07.12', day2: '08.12' },
		{ day1: '14.12', day2: '15.12' },
		{ day1: '21.12', day2: '22.12' }
	];

	return (
		<section className='food__truck__section'>
			<div className='container'>
				{/* Header Section */}
				<div className='row mt-5'>
					<div className='col-md-12 text-center'>
						<h1 className='text__green fw-bold mt-3'>{formatMessage({ id: 'food.truck.header' })}</h1>
						<h3 className='text__orange fw-bold mt-3'>{formatMessage({ id: 'food.truck.sub.header' })}</h3>
					</div>
				</div>

				{/* Dates Section */}
				<div className='row mt-5 justify-content-center'>
					{dates.map(({ day1, day2 }, index) => (
						<div
							key={index}
							className='col-md-12 d-flex align-items-center justify-content-center mt-3'
						>
							<h4 className='text__light__white me-2 mb-0'>
								{formatMessage({ id: 'food.truck.sat' })} {day1}
							</h4>
							<span className='text__light__white mx-3 text-separator'></span>
							<h4 className='text__light__white mb-0'>
								{formatMessage({ id: 'food.truck.sun' })} {day2}
							</h4>
						</div>
					))}
				</div>
				<div className='row text-center mt-5'>
					<div className='col-md-12'>
						<h3 className='text__green fw-bold'>{formatMessage({ id: 'food.truck.hours' })}</h3>
						<h4 className='text__light__white mb-0'>{formatMessage({ id: 'food.truck.sat.pm' })}</h4>
						<h4 className='text__light__white mb-0 mt-2'>{formatMessage({ id: 'food.truck.sun.pm' })}</h4>
					</div>
				</div>
				<div className='row text-center mt-5'>
					<div className='col-md-12'>
						<h3 className='text__green'>{formatMessage({ id: 'food.truck.info' })}</h3>
					</div>
				</div>

				{/* Info Section */}
				<div className='row mt-5'>
					<Info />
				</div>
			</div>
		</section>
	);
};

export default Foodtruck;
