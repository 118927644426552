import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../db';
import { CartType } from '../../types';

export const storeCartData = async (cartData: { uid: string; cart: CartType }) => {
	const userCartRef = doc(db, 'carts', cartData.uid);

	try {
		
		await setDoc(userCartRef, {
			items: cartData.cart.items,
			totalQuantity: cartData.cart.totalQuantity,
			totalPrice: cartData.cart.totalPrice
		});
	} catch (error) {
		console.error('Error storing cart data: ', error);
		throw error;
	}
};


export const getCartData = async (uid: string) => {
	const userCartRef = doc(db, 'carts', uid);

	try {
		const cartDataSnapshot = await getDoc(userCartRef);
		if (cartDataSnapshot.exists()) {
			return cartDataSnapshot.data();
		} else {
			return null;
		}
	} catch (error) {
		throw error;
	}
};

export const updateCartData = async (cartData: { uid: string; cart: CartType }) => {
	const userCartRef = doc(db, 'carts', cartData.uid);

	try {
		await setDoc(userCartRef, {
			items: cartData.cart.items,
			totalQuantity: cartData.cart.totalQuantity,
			totalPrice: cartData.cart.totalPrice
		});
	} catch (error) {
		throw error;
	}
};

export const deleteCart = async (cartData: { uid: string; cart: CartType }) => {
	const userCartRef = doc(db, 'carts', cartData.uid);

	try {
		await setDoc(userCartRef, { items: [], totalQuantity: 0, totalPrice: 0 });
	} catch (error) {
		console.error('Error deleting cart data: ', error);
		throw error;
	}
};
