import React from 'react';
import { InputGroup } from 'react-bootstrap';

const customCookingInstruction = [{ type: 'Indian Spicy' }, { type: 'European Spicy' }];

const SpecialInstructions = ({
	cookingInstructions,
	setCookingInstructions
}: {
	cookingInstructions: string;
	setCookingInstructions: (value: string) => void;
}) => {
	return (
		<div className='card mt-4'>
			<div className='card-body'>
				<div className='custom_user_inputs'>
					<h4 className='text-white'>Special cooking instructions</h4>
					<InputGroup className='mb-3'>
						{customCookingInstruction.map((item, index) => (
							<div
								key={index}
								className='form-check form-check-inline'
							>
								<input
									className='form-check-input'
									type='radio'
									name='customCookingInstructions'
									id={`inlineRadio${index}`}
									value={item.type}
									checked={cookingInstructions === item.type}
									onChange={e => setCookingInstructions(e.target.value)}
									style={{ height: '16px', width: '16px' }}
								/>
								<label
									className='form-check-label ml-2'
									htmlFor={`inlineRadio${index}`}
								>
									{item.type}
								</label>
							</div>
						))}
					</InputGroup>
				</div>
			</div>
		</div>
	);
};

export default SpecialInstructions;
