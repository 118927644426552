import React, { useEffect } from 'react';
import paymentsuccess from '../../assets/paymentSuccess.png';
import './completion.scss';

type Props = {};

const Completion = (props: Props) => {
	const [type, setType] = React.useState<string | null>(null);

	useEffect(() => {
		const extractJSONFromURL = async () => {
			const urlParams = new URLSearchParams(window.location.search);

			if (urlParams.size === 0) return;

			const typeParam = urlParams.get('type');
			setType(typeParam);
		};

		extractJSONFromURL();
	}, []);
	return (
		<section className='completion__section'>
			<div className='conatiner'>
				<div className='row'>
					<div className='col-md-12 text-center'>
						<img
							src={paymentsuccess}
							alt='paymentsuccess'
							className='img-fluid d-block mx-auto'
						/>
						{type === 'menuorder' ? (
							<>
								<h4 className='text-white fw-bold'>The order has been successfully completed!</h4>
							</>
						) : (
							<>
								<h4 className='text-white fw-bold'>You have been successfully subscribbed!</h4>
							</>
						)}

						<a
							href='/orders'
							className='text__green text-ceter mt-4'
						>
							Go to Orders
						</a>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Completion;
