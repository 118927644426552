export const validPostalCodes = [
	'10115',
	'10117',
	'10119',
	'10178',
	'10179',
	'10243',
	'10245',
	'10247',
	'10249',
	'10315',
	'10317',
	'10318',
	'10319',
	'10365',
	'10367',
	'10369',
	'10405',
	'10407',
	'10409',
	'10435',
	'10437',
	'10439',
	'10551',
	'10555',
	'10557',
	'10559',
	'10785',
	'10961',
	'10963',
	'10967',
	'10969',
	'10997',
	'10999',
	'12043',
	'12045',
	'12047',
	'12049',
	'12053',
	'12059',
	'12435',
	'12619',
	'12621',
	'12627',
	'12629',
	'12679',
	'12681',
	'12683',
	'12685',
	'12687',
	'12689',
	'13047',
	'13051',
	'13053',
	'13055',
	'13057',
	'13059',
	'13086',
	'13088',
	'13089',
	'13125',
	'13127',
	'13129',
	'13156',
	'13158',
	'13159',
	'13187',
	'13189',
	'13347',
	'13349',
	'13351',
	'13353',
	'13355',
	'13357',
	'13359',
	'13407',
	'13409',
	'13435',
	'13439',
	'14131',
	'16321',
	'16341'
];
