import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CartItem {
	id: number;
	// quantity: number;
	day: string;
	date?: string;
	menu: string;
	price: number;
	quantity: number;
}

interface CartState {
	cart: CartItem[];
}

const initialState: CartState = {
	cart: []
};

const cartSlice = createSlice({
	name: 'cart',
	initialState,
	reducers: {
		addToCart: (state, action: PayloadAction<CartItem>) => {
			const itemIndex = state.cart.findIndex(item => item.id === action.payload.id);
			// store in firebase

			if (itemIndex !== -1) {
				state.cart[itemIndex].quantity++;
			} else {
				state.cart.push({ ...action.payload, quantity: 1 });
			}
		},
		incrementQuantity: (state, action: PayloadAction<number>) => {
			const itemId = action.payload;
			const item = state.cart.find(item => item.id === itemId);
			if (item) {
				item.quantity++;
			}
		},
		decrementQuantity: (state, action: PayloadAction<number>) => {
			const itemId = action.payload;
			const item = state.cart.find(item => item.id === itemId);
			if (item) {
				if (item.quantity === 1) {
					state.cart = state.cart.filter(item => item.id !== itemId);
				} else {
					item.quantity--;
				}
			}
		},
		removeItem: (state, action: PayloadAction<number>) => {
			const itemId = action.payload;
			state.cart = state.cart.filter(item => item.id !== itemId);
		},
		clearCart: state => {
			state.cart = [];
		}
	}
});

export const { addToCart, incrementQuantity, decrementQuantity, removeItem, clearCart } = cartSlice.actions;

export const cartReducer = cartSlice.reducer;
