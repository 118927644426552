import { ChangeEvent } from 'react';
import flag from '../../assets/flag.png';

interface PhoneInputProps {
	phone: string;
	handlePhoneChange: (event: ChangeEvent<HTMLInputElement>) => void;
	sendOTP: () => void;
	isButtonDisabled: boolean;
	otpReceived: boolean;
}

const PhoneInput = ({ phone, handlePhoneChange, sendOTP, isButtonDisabled, otpReceived }: PhoneInputProps) => (
	<div className='phone-subcontainer'>
		<label
			htmlFor='phoneInput'
			className='form-label'
		>
			Phone Number
		</label>
		<div className='input-group flex-nowrap'>
			<span
				className='input-group-text'
				id='addon-wrapping'
			>
				<img
					src={flag}
					alt='German Flag'
					className='img-fluid'
					style={{ width: '20px', marginRight: '6px' }}
				/>
				+49
			</span>
			<input
				type='text'
				className='form-control'
				placeholder='Enter phone number'
				aria-label='Phone'
				aria-describedby='basic-addon1'
				value={phone}
				onChange={handlePhoneChange}
			/>
		</div>
		<button
			className='btn order__btn mt-5 w-100'
			disabled={isButtonDisabled}
			onClick={sendOTP}
			style={{ display: otpReceived ? 'none' : 'block mx-auto', height: '38px' }}
		>
			{isButtonDisabled ? 'Sending...' : 'Send SMS'}
		</button>
	</div>
);

export default PhoneInput;
